import { useState, useEffect } from "react";
import moment from "moment-timezone";

import {
  PageTitle,
  InputWithLabelSection,
  FilterLabel,
  HeaderContainer,
  Container,
  RoundMiniImage,
  CommonFlex,
  CommonGrid,
  CommonText,
} from "../../styledComponents/common";
import { ColoredButton } from "../../styledComponents/buttons";
import { ActivityTextSection, TabFlex } from "../../styledComponents/members";
import {
  NotesListContainer,
  SingleNoteContainer,
  SingleSection,
  TimeBadge,
  CreatedByDiv,
} from "./workNotesStyles";

import AddSingleMemberDropdown from "../../components/CustomDropdown/AddSingleMemberDropdown";
import DatePicker from "../../components/SingleDatePicker/SingleDatePicker";
import TimezoneDropdown from "../../components/TimezoneDropdown/TimezoneDropdown";

import { TabLeftGrid } from "../../styledComponents/members";

import TopPagination from "../../components/Pagination/TopPagination/TopPagination";
import BottomPagination from "../../components/Pagination/BottomPagination/BottomPagination";

import ComponentCircleLoader from "../../components/Loaders/ComponentCircleLoader";
import NoDataComponent from "../../components/NoDataComponent/NoDataComponent";
import FirstRoundLetterComp from "../../components/FirstRoundLetter/FirstRoundLetter";

import { IconArrow } from "../../styledComponents/Activity";
import LeftArrow_3 from "../../assets/img/icons/LeftArrow_3.svg";
import RightArrow_3 from "../../assets/img/icons/RightArrow_3.svg";
import { FilterButton } from "../../styledComponents/buttons";

const SingleNote = (props) => {
  const { index, note } = props;

  return (
    <SingleNoteContainer key={note.id}>
      <SingleSection>
        <CommonFlex gap="12px">
          {note.user.avatar ? (
            <RoundMiniImage src={note.user.avatar} alt="" />
          ) : (
            <FirstRoundLetterComp
              size="36px"
              fontSize="13px"
              text={`${note.user.first_name} ${note.user.last_name}`.trim()}
              backColor={index}
              color={note.user.color}
            />
          )}

          <CommonText fontSize="13px">
            {`${note.user.first_name} ${note.user.last_name}`.trim()}
          </CommonText>
        </CommonFlex>

        <TimeBadge index={index}>
          <CommonText fontSize="11.5px">
            {`${note.timestamp.format("hh:mm A")} | ${note.timestamp.format(
              "DD/MM/YYYY"
            )}`}
          </CommonText>
        </TimeBadge>
        {note.created_by && note.user.id !== note.created_by.id ? (
          <CreatedByDiv>
            {`Added by ${`${note.created_by.first_name}${
              note.created_by.last_name ? ` ${note.created_by.last_name}` : ``
            }`}`}
          </CreatedByDiv>
        ) : null}
      </SingleSection>
      <SingleSection>
        <ActivityTextSection>
          <CommonGrid
            columns="80px auto"
            justifyContent="flex-start"
            gap="10px"
          >
            <CommonText fontSize="12px" $label fontWeight="500" margin="0">
              Project
            </CommonText>
            <CommonText fontSize="13px" margin="0">
              {note.project ? note.project.name : "-"}
            </CommonText>
          </CommonGrid>

          {note.task && (
            <CommonGrid
              columns="80px auto"
              justifyContent="flex-start"
              gap="10px"
            >
              <CommonText fontSize="12px" $label fontWeight="500" margin="0">
                Task
              </CommonText>
              <CommonText fontSize="13px" margin="0">
                {note.task.name}
              </CommonText>
            </CommonGrid>
          )}
        </ActivityTextSection>
        <CommonGrid
          columns="80px auto"
          justifyContent="flex-start"
          gap="10px"
          margin="15px 0 0"
        >
          <CommonText fontSize="12px" $label fontWeight="500" margin="0">
            Timesheet
          </CommonText>
          <CommonText fontSize="13px" margin="0">
            {`${note.timesheet.start_timestamp.format(
              "hh:mm A"
            )} - ${note.timesheet.end_timestamp.format("hh:mm A")}`}
          </CommonText>
        </CommonGrid>
        <ActivityTextSection margin="25px 0 0">
          <CommonFlex gap="10px">
            <CommonText $label fontWeight="500">
              Note
            </CommonText>
          </CommonFlex>

          <CommonText fontSize="13px" margin="8px 0 0">
            {note.content}
          </CommonText>
        </ActivityTextSection>
      </SingleSection>
    </SingleNoteContainer>
  );
};

const WorkNotes = (props) => {
  const [selectedDate, setSelectedDate] = useState(moment());
  const [selectedMember, setSelectedMember] = useState(null);
  const [selectedTimezone, setSelectedTimezone] = useState(null);

  const [memberOptions, setMemberOptions] = useState([]);
  const isToday = selectedDate.isSame(moment(), "day");

  // pagination
  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  const {
    selectedOrganization,

    getOrganizationMembersList,
    organizationMembersList,

    isLoading,
    getWorkNotes,
    workNotes,

    // pagination
    pageSize,
    totalNotesCount,
  } = props;

  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id) {
      setCurrentPageNumber(1);
      const payload = {
        organization_id: selectedOrganization.id,
        offset: 0,
        limit: pageSize || 20,
      };
      getOrganizationMembersList(payload);
      payload.date = selectedDate.format("YYYY-MM-DD");
      if (selectedOrganization.timezone) {
        payload.timezone = selectedOrganization.timezone;
        setSelectedTimezone({
          type: "Organization",
          timezone: selectedOrganization.timezone,
          offset: selectedOrganization.timezoneoffset,
        });
      }
      getWorkNotes(payload);
    }
  }, [selectedOrganization]);

  useEffect(() => {
    if (organizationMembersList && organizationMembersList.length > 0) {
      setMemberOptions(organizationMembersList);
    } else if (
      organizationMembersList &&
      organizationMembersList.length === 0
    ) {
      setMemberOptions([]);
    }
  }, [organizationMembersList]);

  const singleDateChange = (direction) => {
    setSelectedDate((prevDate) => {
      const newDate =
        direction === "right"
          ? moment(prevDate).add(1, "days")
          : moment(prevDate).subtract(1, "days");
      selectDate(newDate);
      return newDate;
    });
  };

  const selectToday = () => {
    selectDate(moment());
  };

  const selectDate = (date) => {
    setSelectedDate(date);

    if (selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization.id,
        date: date.format("YYYY-MM-DD"),
        offset: 0,
        limit: pageSize || 20,
      };

      if (selectedMember && selectedMember.id) {
        payload.user_id = selectedMember.id;
      }
      if (selectedTimezone && selectedTimezone.timezone) {
        payload.timezone = selectedTimezone.timezone;
      }
      getWorkNotes(payload);
      setCurrentPageNumber(1);
    }
  };

  const onMemberSelect = (member) => {
    if (selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization.id,
        date: selectedDate.format("YYYY-MM-DD"),
        user_id: member.id,
        offset: 0,
        limit: pageSize || 20,
      };
      if (
        selectedTimezone &&
        selectedTimezone.type === "Member" &&
        member.timezone &&
        member.timezone.tz
      ) {
        payload.timezone = member.timezone.tz;
        setSelectedTimezone({
          type: "Member",
          timezone: member.timezone.tz,
          offset: member.timezone.offset,
        });
      } else if (
        selectedTimezone &&
        selectedTimezone.type === "Organization" &&
        selectedTimezone.timezone
      ) {
        payload.timezone = selectedTimezone.timezone;
      }
      getWorkNotes(payload);
      setCurrentPageNumber(1);
    }
    setSelectedMember(member);
  };

  const clearSelectedMember = () => {
    if (selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization.id,
        date: selectedDate.format("YYYY-MM-DD"),
        offset: 0,
        limit: pageSize || 20,
      };

      if (selectedOrganization.timezone) {
        setSelectedTimezone({
          type: "Organization",
          timezone: selectedOrganization.timezone,
          offset: selectedOrganization.timezoneoffset,
        });
        payload.timezone = selectedOrganization.timezone;
      }
      getWorkNotes(payload);
      setCurrentPageNumber(1);
    }
    setSelectedMember(null);
  };

  const onTimezoneSelect = (timezone) => {
    setSelectedTimezone(timezone);
    if (selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization.id,
        date: selectedDate.format("YYYY-MM-DD"),
        offset: pageSize
          ? (currentPageNumber - 1) * pageSize
          : (currentPageNumber - 1) * 20,
        limit: pageSize || 20,
      };
      if (selectedMember && selectedMember.id) {
        payload.user_id = selectedMember.id;
      }
      if (timezone && timezone.timezone) {
        payload.timezone = timezone.timezone;
      }

      getWorkNotes(payload);
    }
  };

  const handlePageChange = (page) => {
    if (selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization.id,
        date: selectedDate.format("YYYY-MM-DD"),
        offset: pageSize ? (page - 1) * pageSize : (page - 1) * 20,
        limit: pageSize || 20,
      };
      if (selectedMember && selectedMember.id) {
        payload.user_id = selectedMember.id;
      }
      if (selectedTimezone && selectedTimezone.timezone) {
        payload.timezone = selectedTimezone.timezone;
      }

      getWorkNotes(payload);
      setCurrentPageNumber(page);
    }
  };

  return (
    <div className="content">
      <PageTitle>Work Notes</PageTitle>
      <HeaderContainer columns="auto auto">
        <CommonFlex>
          <InputWithLabelSection>
            <FilterLabel>Member</FilterLabel>
            <AddSingleMemberDropdown
              assigneeManage={selectedMember}
              updateState={onMemberSelect}
              membersList={memberOptions}
              clearAssignee={() => clearSelectedMember()}
            />
          </InputWithLabelSection>
          <InputWithLabelSection>
            <FilterLabel>Select Date</FilterLabel>
            <CommonFlex gap="2px">
              <DatePicker
                id="workNotesFilterDate"
                date={selectedDate}
                onDateChange={selectDate}
                dateDisplayFormat={"DD/MM/YYYY"}
                placeholder={"Select date..."}
                allowPreviousDates
                disableFutureDates
              />
              <CommonFlex gap="0px" margin="1.5px 0 0 0">
                <IconArrow
                  onClick={() => {
                    singleDateChange("left");
                  }}
                  padding="5px"
                  size="40px"
                  src={LeftArrow_3}
                />
                <IconArrow
                  margin="0 0 0 2px"
                  onClick={() => {
                    if (!isToday) {
                      singleDateChange("right");
                    }
                  }}
                  padding="5px"
                  size="40px"
                  src={RightArrow_3}
                  disabled={isToday}
                />
              </CommonFlex>
              <div style={{ margin: "0 0 0 14px" }}>
                <FilterButton
                  type="gray"
                  padding="10px"
                  onClick={() => selectToday()}
                >
                  Today
                </FilterButton>
              </div>
            </CommonFlex>
          </InputWithLabelSection>
        </CommonFlex>
        {selectedTimezone && (
          <InputWithLabelSection>
            <FilterLabel>Timezone</FilterLabel>
            <TimezoneDropdown
              selectedOrganization={selectedOrganization}
              selectedMember={selectedMember}
              selectTimezone={onTimezoneSelect}
              selectedTimezone={selectedTimezone}
              dropdownWidth="140px"
            />
          </InputWithLabelSection>
        )}
      </HeaderContainer>
      {totalNotesCount && totalNotesCount > 0 && pageSize ? (
        <TopPagination
          itemName="notes"
          totalCount={totalNotesCount}
          currentPage={currentPageNumber}
          onPageChange={(page) => handlePageChange(page)}
          pageSize={pageSize}
        />
      ) : null}
      <Container padding="30px">
        {isLoading ? (
          <ComponentCircleLoader />
        ) : workNotes && workNotes.length > 0 ? (
          <NotesListContainer>
            {workNotes.map((note, index) => {
              return <SingleNote index={index} note={note} />;
            })}
          </NotesListContainer>
        ) : (
          <NoDataComponent
            title={`No notes added${
              selectedMember ? ` for ${selectedMember.name}` : ""
            } in the selected date`}
          />
        )}
      </Container>
      {totalNotesCount && totalNotesCount > 0 && pageSize ? (
        <BottomPagination
          totalCount={totalNotesCount}
          currentPage={currentPageNumber}
          onPageChange={(page) => handlePageChange(page)}
          pageSize={pageSize}
        />
      ) : null}
    </div>
  );
};

export default WorkNotes;
