import { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";

import queryString from "query-string";
import FirstRoundLetterComp from "../../../components/FirstRoundLetter/FirstRoundLetter";
import moment from "moment";

import {
  AssigneeRoundImage,
  RoundPersonImage,
  CrossIcon,
} from "../../../styledComponents/createProject";

import {
  NameRoleSection,
  TeamMemberDivContainer,
  TeamMemberDiv,
  TeamMemberAvatar,
  TeamMemberRole,
  TeamProjectsDivContainer,
  ContainerUpperPart,
  ContainerUpperTextSection,
  DropdownImage,
  TeamEditSection,
} from "../../../styledComponents/teams";

import {
  RoundLetterSection,
  TableText,
  PageTitle,
  Container,
  CommonText,
  InputField,
  ArrowBox,
  BackLinkSection,
  ManagerBadge,
  ContentHeader,
  AssignListContainer,
  AssignListItem,
  AssignListText,
  SearchBarWithAssignListContainer,
  AssigneeSearchBarWrapper,
  AssigneeSearchBarContainer,
  AssigneeSearchIcon,
  AssigneeSearchInput,
} from "../../../styledComponents/common";
import {
  ColoredButtonWithIcon,
  ButtonIcon,
  WhiteButtonWithIcon,
  WhiteButton,
  TabButtonContainer,
  TabButton,
} from "../../../styledComponents/buttons";
import {
  HoveredSection,
  RoundHover,
  ToolTipContainer,
  InnerDivTooltip,
  TooltipText,
} from "../../../styledComponents/clients";
import { TeamEditIconContainer, TabAndSearchRow } from "../teamsStyles";

import { CommFirstRoundLetterComp } from "../CommonComponents";

import TeamDeleteModal from "../TeamDeleteModal";
import DropdownLoader from "../../../components/DropdownLoader/DropdownLoader";
import AddProjectDropdown from "../../../components/CustomDropdown/AddProjectDropdown";
import BackButtonComponent from "../../../components/BackButtonComponent/BackButtonComponent";
import AssignButtonLoader from "../../../components/AssignButtonLoader/AssignButtonLoader";
import ComponentCircleLoader from "../../../components/Loaders/ComponentCircleLoader";

import SearchWithButton from "../../../components/Search/SearchWithButton";

import TeamMembers from "./TeamMembers";
import AssignedProjects from "./AssignedProjects";

import Chat from "../../../assets/img/icons/chat.svg";
import Plus from "../../../assets/img/icons/plus_white.svg";
import Assign from "../../../assets/img/icons/assign2.svg";
import Bean from "../../../assets/img/icons/delete.svg";
import Badge from "../../../assets/img/icons/manager_badge.svg";
import Pencil from "../../../assets/img/icons/pencil.svg";
import EditIcon from "../../../assets/img/icons/edit.svg";
import TickDark from "../../../assets/img/icons/tick.svg";
import searchIcon from "../../../assets/img/icons/search.svg";
import crossIcon from "../../../assets/img/icons/cross_black.svg";
import FirstRoundLetter from "../../../components/ColorLetterRound/FirstRoundLetter";

const DropdownComp = ({
  isLoading,
  selectedOrganization,
  assigneeManage,
  index,
  updateState,
  membersList,
  getMembersList,
  teamMemberIsLoading,
  loadingMemberId,
}) => {
  const [addAssignee, setAddAssignee] = useState(false);
  const wrapperRef1 = useRef(null);
  const wrapperRef2 = useRef(null);
  const [addList, setAddList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (membersList && membersList.length > 0) {
      let newArr = membersList;
      if (assigneeManage && assigneeManage.length > 0) {
        assigneeManage.map((assignee) => {
          newArr = newArr.filter((item) => item.id !== assignee.user.id);
        });
      }
      setAddList(newArr);
    } else if (membersList && membersList.length === 0) {
      setAddList([]);
    }
  }, [membersList, assigneeManage]);

  useEffect(() => {
    if (
      searchTerm &&
      searchTerm !== "" &&
      membersList &&
      membersList.length > 0
    ) {
      let originalList = membersList;
      let filteredList = [];
      originalList.forEach((e) => {
        if (
          searchTerm &&
          e.name.toLowerCase().indexOf(searchTerm.toLowerCase().trim()) === -1
        ) {
          return;
        }
        filteredList.push(e);
      });
      if (assigneeManage) {
        filteredList = filteredList.filter(
          (item) => item.id !== assigneeManage.id
        );
      }
      setAddList(filteredList);
    } else if (searchTerm === "") {
      let newArr = membersList;
      if (assigneeManage) {
        newArr = newArr.filter((item) => item.id !== assigneeManage.id);
      }
      setAddList(newArr);
    }
  }, [searchTerm]);

  const onSearchTermChange = (e) => {
    const { value } = e.target;
    setSearchTerm(value);
  };

  //function
  useOutsideClickHandler(wrapperRef1, wrapperRef2);

  const showAssignee = () => {
    if (selectedOrganization && selectedOrganization.id) {
      let payload = {
        organization_id: selectedOrganization.id,
      };
      getMembersList(payload);
    }
    setAddAssignee(true);
  };

  function useOutsideClickHandler(ref1, ref2) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (
          ref1.current &&
          !ref1.current.contains(event.target) &&
          ref2.current &&
          !ref2.current.contains(event.target)
        ) {
          setAddAssignee(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref1, ref2]);
  }
  return (
    <div style={{ position: `relative` }}>
      <DropdownImage
        onClick={() => {
          showAssignee();
        }}
        ref={wrapperRef2}
      >
        <img src={Assign} alt="assign2" width="100%" />
      </DropdownImage>
      {addAssignee && (
        <AssignListContainer ref={wrapperRef1}>
          <SearchBarWithAssignListContainer>
            <AssigneeSearchBarWrapper>
              <AssigneeSearchBarContainer>
                <AssigneeSearchIcon src={searchIcon} alt="" />
                <AssigneeSearchInput
                  value={searchTerm}
                  onChange={(e) => onSearchTermChange(e)}
                  placeholder="Search..."
                />
              </AssigneeSearchBarContainer>
            </AssigneeSearchBarWrapper>

            <DropdownLoader loading={isLoading} />
            {addList &&
              addList.length > 0 &&
              addList.map((person, i) => {
                return teamMemberIsLoading &&
                  loadingMemberId &&
                  loadingMemberId === person.id ? (
                  <AssignListItem columns="100%" key={i}>
                    <DropdownLoader loading />
                  </AssignListItem>
                ) : (
                  <AssignListItem
                    key={i}
                    onClick={() => {
                      updateState(person, index);
                    }}
                    columns="30px auto"
                    padding="7px 8px"
                  >
                    <AssigneeRoundImage index={0} size="30px">
                      {person.avatar ? (
                        <RoundPersonImage
                          src={person.avatar}
                          alt=""
                          size="30px"
                          borderColor="#c2cce1"
                        />
                      ) : (
                        <FirstRoundLetterComp
                          text={person.name}
                          fontSize="12px"
                          backColor={index}
                          size="30px"
                          color={person.color ?? null}
                        />
                      )}
                    </AssigneeRoundImage>
                    <AssignListText>{person.name}</AssignListText>
                  </AssignListItem>
                );
              })}
          </SearchBarWithAssignListContainer>
        </AssignListContainer>
      )}
    </div>
  );
};

const TeamDetails = (props) => {
  const [teamIndex, setTeamIndex] = useState(null);
  const [details, setDetails] = useState(null);
  const [modal, setModal] = useState(false);
  const [editName, setEditName] = useState(false);
  const [teamName, setTeamName] = useState("");
  const [selectedTab, setSelectedTab] = useState("members");

  const [searchTerm, setSearchTerm] = useState("");
  const [currentSearchedTerm, setCurrentSearchedTerm] = useState("");

  const {
    history,
    location,
    selectedOrganization,
    getTeamDetails,
    teamDetails,
    updateTeam,
    deleteTeam,
    getTeamMembersList,
    teamMemberListLoading,
    teamMembersList,

    addTeamMember,
    updateTeamMember,
    removeTeamMember,
    teamMemberIsLoading,
    loadingMemberId,
    getTeamProjectsList,
    teamProjectListLoading,
    teamProjectsList,

    assignProject,
    isLoading,
    projectIsLoading,
    memberListIsLoading,
    orgMembersList,
    getOrganizationMembersList,
    projectListIsLoading,
    getProjectShortList,
    projectList,
    deleteTeamLoading,

    // pagination
    teamMembersCount,
    teamMembersPageSize,
    teamProjectsCount,
    teamProjectsPageSize,
  } = props;

  const { search } = location;

  useEffect(() => {
    return () => {
      setDetails(null);
    };
  }, []);

  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id) {
      if (search) {
        const queryParsed = queryString.parse(search);
        if (queryParsed.index) {
          setTeamIndex(parseInt(queryParsed.index));
        }
        let payload = {
          organization_id: selectedOrganization.id,
          team_id: queryParsed.id,
        };
        getTeamDetails(payload);
        getTeamMembersList(payload);
      }
    }
  }, [selectedOrganization]);

  useEffect(() => {
    if (teamDetails && teamDetails !== null) {
      setDetails(teamDetails);
    }
  }, [teamDetails]);

  const onTabSwitch = (tab) => {
    setSelectedTab(tab);
    if (
      tab === "projects" &&
      selectedOrganization &&
      selectedOrganization.id &&
      teamDetails &&
      teamDetails.id
    ) {
      const payload = {
        organization_id: selectedOrganization.id,
        team_id: teamDetails.id,
      };
      setSearchTerm("");
      setCurrentSearchedTerm("");
      getTeamProjectsList(payload);
    }
  };

  const handleSearchByName = () => {
    if (
      selectedOrganization &&
      selectedOrganization.id &&
      teamDetails &&
      teamDetails.id
    ) {
      const payload = {
        organization_id: selectedOrganization.id,
        team_id: teamDetails.id,
      };
      if (searchTerm && searchTerm.trim()) {
        payload.name = searchTerm.toLowerCase().trim();
        setCurrentSearchedTerm(searchTerm);
      } else {
        setCurrentSearchedTerm("");
      }
      if (selectedTab === "members") {
        getTeamMembersList(payload);
      } else {
        getTeamProjectsList(payload);
      }
    }
  };
  const handleClearSearch = () => {
    if (
      currentSearchedTerm &&
      selectedOrganization &&
      selectedOrganization.id &&
      teamDetails &&
      teamDetails.id
    ) {
      const payload = {
        organization_id: selectedOrganization.id,
        team_id: teamDetails.id,
      };
      if (selectedTab === "members") {
        getTeamMembersList(payload);
      } else {
        getTeamProjectsList(payload);
      }
    }
    setTimeout(() => {
      setSearchTerm("");
      setCurrentSearchedTerm("");
    }, 100);
  };
  const onSearchTermChange = (e) => {
    const { value } = e.target;
    setSearchTerm(value);
  };

  const toggleDeleteModal = () => {
    setModal(!modal);
  };

  const editTeamName = () => {
    setEditName(true);
    setTeamName(details.name);
  };

  const onTeamNameChange = (e) => {
    const { value } = e.target;
    setTeamName(value);
  };

  const updateTeamName = () => {
    // let object = details;
    // object.name = teamName;
    // setDetails(object);
    if (
      selectedOrganization &&
      selectedOrganization.id &&
      details &&
      details.id &&
      teamName
    ) {
      let payload = {
        organization_id: selectedOrganization.id,
        team_id: details.id,
        name: teamName,
      };
      updateTeam(payload);
      setEditName(false);
    }
  };

  const handleDeleteTeam = () => {
    if (
      selectedOrganization &&
      selectedOrganization.id &&
      details &&
      details.id
    ) {
      let payload = {
        organization_id: selectedOrganization.id,
        team_id: details.id,
      };
      deleteTeam(payload);

      setTimeout(() => {
        toggleDeleteModal();
        history.push("/user/teams");
      }, 300);
    }
  };

  const handleAddTeamMember = (member) => {
    if (
      selectedOrganization &&
      selectedOrganization.id &&
      details &&
      details.id &&
      member
    ) {
      let payload = {
        organization_id: selectedOrganization.id,
        team_id: details.id,
        user_id: member.id,
        // getDetails: true,
      };
      addTeamMember(payload);
    }
  };
  const handleUpdateTeamMember = (canManage, memberId) => {
    if (
      selectedOrganization &&
      selectedOrganization.id &&
      details &&
      details.id &&
      memberId
    ) {
      const payload = {
        organization_id: selectedOrganization.id,
        team_id: details.id,
        member_id: memberId,
        can_manage: canManage,
      };
      updateTeamMember(payload);
    }
  };
  const handleRemoveTeamMember = (memberId) => {
    if (
      selectedOrganization &&
      selectedOrganization.id &&
      details &&
      details.id &&
      memberId
    ) {
      let payload = {
        organization_id: selectedOrganization.id,
        team_id: details.id,
        user_id: memberId,
        // getDetails: true,
      };
      removeTeamMember(payload);
    }
  };

  const handleAssignProject = (project) => {
    if (
      selectedOrganization &&
      selectedOrganization.id &&
      details &&
      details.id &&
      project &&
      project.id
    ) {
      let payload = {
        organization_id: selectedOrganization.id,
        project_id: project.id,
        team_id: details.id,
        projectData: project,
        // getDetails: true,
        getProjects: true,
      };
      assignProject(payload);
    }
  };

  return (
    <div className="content">
      <PageTitle>Teams</PageTitle>
      <ContentHeader>
        <BackButtonComponent
          onClick={() => history.push("/user/teams")}
          subTitle="Back to all teams"
        />
        {/* {selectedOrganization &&
          (selectedOrganization.role === "owner" ||
            selectedOrganization.role === "admin") && (
            <ColoredButtonWithIcon
              onClick={() => history.push("/user/add-team")}
            >
              <ButtonIcon src={Plus} alt="" />
              Add Team
            </ColoredButtonWithIcon>
          )} */}
      </ContentHeader>

      <Container padding="30px 0">
        {isLoading ? (
          <ComponentCircleLoader />
        ) : details && details !== null ? (
          <>
            <ContainerUpperPart>
              <ContainerUpperTextSection>
                <TeamEditSection>
                  {editName ? (
                    <>
                      <InputField
                        width="100%"
                        type="text"
                        placeholder="edit team name..."
                        value={teamName}
                        onChange={(e) => onTeamNameChange(e)}
                      />
                      <ArrowBox isActionButton onClick={() => updateTeamName()}>
                        <img src={TickDark} alt="" width="22px" />
                      </ArrowBox>
                    </>
                  ) : (
                    <>
                      <RoundLetterSection gap="20px">
                        <FirstRoundLetterComp
                          text={details.name}
                          backColor={teamIndex}
                          size="40px"
                          fontSize="16px"
                          color={details.color || null}
                        />
                        <TableText
                          style={{
                            maxWidth: "300px",
                            textAlign:'justify',
                            whiteSpace: "wrap",
                          }}
                          name
                          title={details.name}
                        >
                          {details.name}
                        </TableText>
                      </RoundLetterSection>

                      <TeamEditIconContainer
                        size="35px"
                        onClick={() => editTeamName()}
                      >
                        <img src={EditIcon} alt="" width="16px" />
                      </TeamEditIconContainer>
                    </>
                  )}
                </TeamEditSection>
                <CommonText
                  fontWeight="600"
                  fontSize="13px"
                  style={{ whiteSpace: `nowrap` }}
                >
                  {`Created On ${moment(details.created_at).format(
                    "MMM DD, YYYY"
                  )}`}
                </CommonText>

                {selectedOrganization &&
                (selectedOrganization.role === "owner" ||
                  selectedOrganization.role === "admin") ? (
                  <BackLinkSection
                    style={{ cursor: `auto`, gridColumnGap: `12px` }}
                  >
                    <DropdownComp
                      isLoading={memberListIsLoading}
                      selectedOrganization={selectedOrganization}
                      assigneeManage={details.members}
                      updateState={handleAddTeamMember}
                      membersList={orgMembersList}
                      getMembersList={getOrganizationMembersList}
                      teamMemberIsLoading={teamMemberIsLoading}
                      loadingMemberId={loadingMemberId}
                    />
                    {/* <CommonText title>Add Member</CommonText> */}
                  </BackLinkSection>
                ) : null}

                {projectIsLoading ? (
                  <AssignButtonLoader />
                ) : (
                  <AddProjectDropdown
                    isLoading={projectListIsLoading}
                    selectedOrganization={selectedOrganization}
                    assignedProjects={teamProjectsList}
                    getProjectList={getProjectShortList}
                    projectsList={projectList}
                    updateState={handleAssignProject}
                  />
                )}
              </ContainerUpperTextSection>

              {selectedOrganization &&
              (selectedOrganization.role === "owner" ||
                selectedOrganization.role === "admin") ? (
                <WhiteButtonWithIcon
                  padding="0 10px"
                  type="delete"
                  fontSize="14px"
                  margin="auto 0"
                  onClick={() => toggleDeleteModal()}
                >
                  <ButtonIcon src={Bean} alt="" />
                  Delete Team
                </WhiteButtonWithIcon>
              ) : null}

              <TeamDeleteModal
                isOpen={modal}
                toggle={toggleDeleteModal}
                handleDelete={handleDeleteTeam}
                isLoading={deleteTeamLoading}
              />
            </ContainerUpperPart>

            <TabAndSearchRow>
              <TabButtonContainer>
                <TabButton
                  onClick={() => onTabSwitch("members")}
                  selected={selectedTab}
                  index="members"
                >
                  Team Members
                </TabButton>
                <TabButton
                  onClick={() => onTabSwitch("projects")}
                  selected={selectedTab}
                  index="projects"
                >
                  Assigned Projects
                </TabButton>
              </TabButtonContainer>
              <SearchWithButton
                itemName={selectedTab === "members" ? "Member" : "Project"}
                searchTermName={
                  selectedTab === "members" ? "member name" : "project name"
                }
                searchInput={searchTerm}
                onInputChange={onSearchTermChange}
                handleSearch={handleSearchByName}
                handleClearSearch={handleClearSearch}
                noLabel
              />
            </TabAndSearchRow>

            {selectedTab === "members" ? (
              <TeamMembers
                selectedOrganization={selectedOrganization}
                teamId={details && details.id}
                teamMemberListLoading={teamMemberListLoading}
                membersCount={teamMembersCount}
                pageSize={teamMembersPageSize}
                getTeamMembersList={getTeamMembersList}
                membersList={teamMembersList}
                updateMember={handleUpdateTeamMember}
                deleteMember={handleRemoveTeamMember}
                teamMemberIsLoading={teamMemberIsLoading}
                loadingMemberId={loadingMemberId}
                history={history}
                searchTerm={searchTerm}
              />
            ) : (
              <AssignedProjects
                selectedOrganization={selectedOrganization}
                teamId={details && details.id}
                teamProjectListLoading={teamProjectListLoading}
                projectsCount={teamProjectsCount}
                pageSize={teamProjectsPageSize}
                getTeamProjectsList={getTeamProjectsList}
                projectsList={teamProjectsList}
                history={history}
                searchTerm={searchTerm}
              />
            )}
          </>
        ) : null}
      </Container>
    </div>
  );
};

export default TeamDetails;
