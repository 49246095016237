import { connect } from "react-redux";

import AddOrJoinOrg from "./AddOrJoinOrg";

import { getJoinRequests } from "../RequestJoinOrg/requestJoinOrgActions";

const mapStateToProps = (state) => ({
  joinRequest: state.requestJoinOrg.joinRequest,
  advertise: state.signUp.advertise,
});

// Actions prop to dispatch
const mapDispatchToProps = (dispatch) => ({
  getJoinRequests: () => dispatch(getJoinRequests()),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(AddOrJoinOrg);
