import { useState, useEffect } from "react";
import { TeamMembersContainer, TeamMemberCard } from "../teamsStyles";
import { TeamMemberAvatar } from "../../../styledComponents/teams";
import { CrossIcon } from "../../../styledComponents/createProject";
import {
  ManagerBadge,
  CommonText,
  CommonFlex,
  ColoredText,
} from "../../../styledComponents/common";
import {
  HoveredSection,
  RoundHover,
  ToolTipContainer,
  InnerDivTooltip,
  TooltipText,
} from "../../../styledComponents/clients";

import FirstRoundLetterComp from "../../../components/FirstRoundLetter/FirstRoundLetter";
import DropdownLoader from "../../../components/DropdownLoader/DropdownLoader";
import ComponentCircleLoader from "../../../components/Loaders/ComponentCircleLoader";
import NoDataComponent from "../../../components/NoDataComponent/NoDataComponent";

import TopPagination from "../../../components/Pagination/TopPagination/TopPagination";
import BottomPagination from "../../../components/Pagination/BottomPagination/BottomPagination";

import badgeIcon from "../../../assets/img/icons/manager_badge.svg";
import badgeGrayIcon from "../../../assets/img/icons/manager-badge-grey.svg";
import crossIcon from "../../../assets/img/icons/cross_black.svg";
import chatIcon from "../../../assets/img/icons/chat.svg";
import CommonTooltip from "../../../components/Tooltip/CommonTooltip";

const TeamMembers = (props) => {
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [toolTip, setToolTip] = useState(false);

  const [managerTooltip, setManagerTooltip] = useState(false);

  // pagination
  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  const {
    selectedOrganization,
    teamId,
    getTeamMembersList,
    teamMemberListLoading,
    membersList,
    deleteMember,
    updateMember,
    teamMemberIsLoading,
    loadingMemberId,
    history,
    searchTerm,

    // pagination
    membersCount,
    pageSize,
  } = props;

  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id) {
      setCurrentPageNumber(1);
    }
  }, [selectedOrganization]);

  const handlePageChange = (page) => {
    if (selectedOrganization && selectedOrganization.id && teamId) {
      const payload = {
        organization_id: selectedOrganization.id,
        team_id: teamId,
        page,
      };
      getTeamMembersList(payload);
    }
    setCurrentPageNumber(page);
  };

  const onHoverChange = (index) => {
    setSelectedIndex(index);
  };

  const toolTipChange = () => {
    setToolTip(!toolTip);
  };

  if (teamMemberListLoading) {
    return <ComponentCircleLoader padding="12vh 0" />;
  }
  if (membersList && membersList.length === 0) {
    return (
      <NoDataComponent
        title={
          searchTerm
            ? "No team member found with this name!"
            : "No member in this team!"
        }
      />
    );
  }

  return (
    <>
      {membersCount && membersCount > 0 && pageSize ? (
        <TopPagination
          itemName="members"
          totalCount={membersCount}
          currentPage={currentPageNumber}
          onPageChange={(page) => handlePageChange(page)}
          pageSize={pageSize}
          padding="0 30px"
          margin="0 0 15px"
        />
      ) : null}
      <TeamMembersContainer>
        {membersList.map((member, index) => {
          if (
            member.id &&
            loadingMemberId &&
            member.id === loadingMemberId &&
            teamMemberIsLoading
          ) {
            return (
              <TeamMemberCard key={index}>
                <DropdownLoader loading />
              </TeamMemberCard>
            );
          }

          return (
            <TeamMemberCard
              key={index}
              onMouseEnter={() => onHoverChange(index)}
              onMouseLeave={() => onHoverChange(-1)}
            >
              {member.can_manage && (
                <div
                  style={{
                    position: `absolute`,
                    top: "12px",
                    left: "12px",
                  }}
                >
                  <ManagerBadge
                    src={badgeIcon}
                    alt=""
                    width="24px"
                    style={{ cursor: "pointer", position: "unset" }}
                    onMouseEnter={() => setManagerTooltip("manager")}
                    onMouseLeave={() => setManagerTooltip(false)}
                    onClick={() => updateMember(false, member.id)}
                  />
                  {managerTooltip &&
                    managerTooltip === "manager" &&
                    selectedIndex === index && (
                      <CommonTooltip
                        tooltipText="Remove Manager"
                        left="-54px"
                        top="-35px"
                        width="130px"
                        height="25px"
                        padding="7px 10px"
                      />
                    )}
                </div>
              )}
              {member.user.avatar ? (
                <TeamMemberAvatar src={member.user.avatar} alt="" />
              ) : (
                <FirstRoundLetterComp
                  size="40px"
                  fontSize="14px"
                  text={`${member.user.first_name}${
                    member.user.last_name ? ` ${member.user.last_name}` : ``
                  }`}
                  backColor={index}
                  color={member.user.color || null}
                />
              )}

              <CommonText
                margin="12px 0 0"
                style={{ wordBreak: "break-all" }}
              >{`${member.user.first_name}${
                member.user.last_name ? ` ${member.user.last_name}` : ``
              }`}</CommonText>
              <CommonText
                $label
                fontSize="12px"
                fontWeight="500"
                margin="10px 0 0"
                style={{ wordBreak: "break-all", textAlign: "center" }}
              >
                {member.user.email}
              </CommonText>
              {/* <CommonFlex gap="5px" margin="12px 0 0">
              <CommonText $label fontWeight="500">
                Timezone:
              </CommonText>
              <CommonText>{member.user.timezone.tz}</CommonText>
            </CommonFlex> */}
              <ColoredText
                margin="15px 0 0 0"
                hover
                primary
                semibold
                fontSize="14px"
                style={{ cursor: "pointer" }}
                onClick={() =>
                  member.user.id &&
                  history.push(`/user/member-details?id=${member.user.id}`)
                }
              >
                View Details
              </ColoredText>
              {selectedIndex === index && !member.can_manage && (
                <div
                  style={{
                    position: `absolute`,
                    top: "12px",
                    left: "12px",
                  }}
                >
                  <ManagerBadge
                    src={badgeGrayIcon}
                    alt=""
                    width="24px"
                    style={{ cursor: "pointer", position: "unset" }}
                    onMouseEnter={() => setManagerTooltip(true)}
                    onMouseLeave={() => setManagerTooltip(false)}
                    onClick={() => updateMember(true, member.id)}
                  />
                  {managerTooltip && (
                    <CommonTooltip
                      tooltipText="Make Manager"
                      left="-45px"
                      top="-35px"
                      width="115px"
                      height="25px"
                      padding="7px 10px"
                    />
                  )}
                </div>
              )}
              {selectedIndex === index && (
                <HoveredSection columns="1fr" right="10px">
                  <RoundHover
                    onMouseEnter={() => toolTipChange()}
                    onMouseLeave={() => toolTipChange()}
                    onClick={() => deleteMember(member.id)}
                  >
                    <CrossIcon src={crossIcon} alt="" />
                    {toolTip && (
                      <ToolTipContainer>
                        <InnerDivTooltip>
                          <img src={chatIcon} alt="chat" width="64px" />
                          <TooltipText>Delete</TooltipText>
                        </InnerDivTooltip>
                      </ToolTipContainer>
                    )}
                  </RoundHover>
                </HoveredSection>
              )}
            </TeamMemberCard>
          );
        })}
      </TeamMembersContainer>
      {membersCount && membersCount > 0 && pageSize ? (
        <BottomPagination
          totalCount={membersCount}
          currentPage={currentPageNumber}
          onPageChange={(page) => handlePageChange(page)}
          pageSize={pageSize}
        />
      ) : null}
    </>
  );
};

export default TeamMembers;
