import { connect } from "react-redux";
import Invoice from "./Invoice";

// Actions
import {
  getInvoiceList,
  recordPaymentInvoice,
  createInvoiceUpdateSendLog,
  sendInvoiceEmail,
  deleteInvoice,
} from "./invoiceActions";

import { getClientDetails, getClientList } from "../Clients/clientsActions";

// Store props to map with current state
const mapStateToProps = (state) => ({
  selectedOrganization: state.organization.selectedOrganization,
  clientList: state.client.clientList,
  clientDetails: state.client.clientDetails,
  profileData: state.profile.profileData,

  isLoading: state.invoice.isLoading,
  sendMailLoading: state.invoice.sendMailLoading,
  recordPaymentLoading: state.invoice.recordPaymentLoading,
  deleteInvoiceLoading: state.invoice.deleteInvoiceLoading,
  noData: state.invoice.noData,
  invoiceList: state.invoice.invoiceList,
  updateInvoiceSendLog: state.invoice.updateInvoiceSendLog,
  sendInvoiceEmailInfo: state.invoice.sendInvoiceEmailInfo,
  recordPaymentInfo: state.invoice.recordPaymentInfo,
  deletedInvoiceCallback: state.invoice.deletedInvoiceCallback,
  reloadInvoiceList: state.invoice.reloadInvoiceList,

  // pagination
  totalInvoicesCount: state.invoice.totalInvoicesCount,
  invoicePageSize: state.invoice.invoicePageSize,
});

// Actions prop to dispatch
const mapDispatchToProps = (dispatch) => ({
  getInvoiceList: (details) => dispatch(getInvoiceList({ ...details })),
  recordPaymentInvoice: (details) =>
    dispatch(recordPaymentInvoice({ ...details })),
  createInvoiceUpdateSendLog: (details) =>
    dispatch(createInvoiceUpdateSendLog({ ...details })),
  sendInvoiceEmail: (details) => dispatch(sendInvoiceEmail({ ...details })),
  deleteInvoice: (details) => dispatch(deleteInvoice({ ...details })),

  getClientList: (details) => dispatch(getClientList({ ...details })),
  getClientDetails: (details) => dispatch(getClientDetails({ ...details })),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(Invoice);
