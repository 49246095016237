import { useState, useEffect } from "react";
import classnames from "classnames";
import moment from "moment";
import { Container } from "reactstrap";

import {
  NavbarContainer,
  NavbarWrapper,
  NavbarToggle,
  NavbarTogglerButton,
  NavbarTogglerBar,
  OrganizationSectionWrapper,
  OrganizationDiv,
  IconAndNameContainer,
  OrganizationIcon,
  OrganizationName,
  OrgArrowIcon,
} from "./adminNavbarStyles";

import TrialEndBar from "../Subscription/TrialEndBar";

import arrowIcon from "../../assets/img/Sidebar/sidebar-arrow-down.svg";

import OrganizationDropdown from "./OrganizationDropdown";

export const remainingDays = (endDate) => {
  let today = moment();
  const diffDays = Math.ceil(moment(endDate).diff(today, "days"));
  return diffDays + 1;
};

const shouldShowNotificationBar = ({
  subscriptionStatus,
  cancellationDate,
  role,
  isEnterprise,
  location,
}) => {
  const isValidSubscriptionStatus =
    subscriptionStatus === "trialing" ||
    subscriptionStatus === "suspended" ||
    subscriptionStatus === "paused" ||
    (subscriptionStatus === "cancelled" &&
      cancellationDate &&
      remainingDays(cancellationDate) <= 14) ||
    (subscriptionStatus === "cancelled" && !cancellationDate);

  const isExcludedPath = [
    "/user/add-organization",
    "/user/current-plan",
    "/user/subscription",
    "/user/checkout",
    "/user/paddle-payment-checkout",
  ].includes(location.pathname);

  return (
    subscriptionStatus &&
    isValidSubscriptionStatus &&
    role === "owner" &&
    !isEnterprise &&
    location &&
    !isExcludedPath
  );
};

const OrganizationSection = (props) => {
  const [organizationDropdownOpen, setOrganizationDropdownOpen] =
    useState(false);

  const closeDropdown = () => {
    setOrganizationDropdownOpen(false);
  };
  return (
    <OrganizationSectionWrapper collapseOpen={props.collapseOpen}>
      <OrganizationDropdown
        closeDropdown={closeDropdown}
        dropdownOpen={organizationDropdownOpen}
        history={props.history}
        selectedOrgId={props.selectedOrgId}
        activeOrgsList={props.activeOrgsList}
        archivedOrgsList={props.archivedOrgsList}
        selectOrg={props.selectOrg}
      />
      <OrganizationDiv onClick={() => setOrganizationDropdownOpen(true)}>
        {props.selectedOrgName && (
          <IconAndNameContainer>
            <OrganizationIcon
              archived={!props.isActive}
              bgColor={props.selectedOrgColor}
            >
              {props.selectedOrgName.charAt(0).toUpperCase()}
            </OrganizationIcon>
            <OrganizationName archived={!props.isActive}>
              {props.selectedOrgName.length < 25
                ? props.selectedOrgName
                : `${props.selectedOrgName.slice(0, 25)}...`}
            </OrganizationName>
          </IconAndNameContainer>
        )}
        <OrgArrowIcon
          src={arrowIcon}
          alt=""
          dropdownOpen={organizationDropdownOpen}
        />
      </OrganizationDiv>
    </OrganizationSectionWrapper>
  );
};

const AdminNavbar = (props) => {
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [color, setColor] = useState("navbar-transparent");

  const [role, setRole] = useState(null);
  const [isEnterprise, setIsEnterprise] = useState(true);
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const [trialEndDate, setTrialEndDate] = useState(null);
  const [cancellationDate, setCancellationDate] = useState(null);

  const {
    history,
    location,
    profileOperation,
    selectOrganization,
    handleNavOpen,
    trialInfo,
    sidebarState,
    selectedOrganization,
  } = props;

  useEffect(() => {
    window.addEventListener("resize", updateColor);
    // getOrganizationList();

    const payload = {
      method: "GET",
    };
    profileOperation(payload);
    if (
      selectedOrganization &&
      selectedOrganization.id &&
      selectedOrganization.subscription &&
      selectedOrganization.subscription.id
    ) {
      setSubscriptionStatus(selectedOrganization.subscription.status);
      setTrialEndDate(selectedOrganization.subscription.trial_till);
      setRole(selectedOrganization.role);
    }

    return () => {
      setSubscriptionStatus(null);
      setTrialEndDate(null);
      setRole(null);
    };
  }, []);

  useEffect(() => {
    if (
      selectedOrganization &&
      selectedOrganization.id &&
      selectedOrganization.subscription &&
      selectedOrganization.subscription.id
    ) {
      setSubscriptionStatus(selectedOrganization.subscription.status);
      setTrialEndDate(selectedOrganization.subscription.trial_till);
      setRole(selectedOrganization.role);
      if (selectedOrganization.subscription.cancellation_effective_date) {
        setCancellationDate(
          selectedOrganization.subscription.cancellation_effective_date
        );
      }
    }
    if (selectedOrganization && selectedOrganization.id) {
      setIsEnterprise(selectedOrganization.is_enterprise);
    }
  }, [selectedOrganization]);

  useEffect(() => {
    if (
      window.innerWidth < 992 &&
      history.location.pathname !== location.pathname &&
      document.documentElement.classList.toggle("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      handleNavOpen();
    }
  }, [location]);

  useEffect(() => {
    // trial related works
  }, [trialInfo]);

  const updateColor = () => {
    if (window.innerWidth < 993 && collapseOpen) {
      setColor("bg-white");
    } else {
      setColor("navbar-transparent");
    }
  };

  const toggleSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
    handleNavOpen();
  };

  const toggleCollapse = () => {
    if (!collapseOpen) {
      setColor("bg-white");
    } else {
      setColor("navbar-transparent");
    }
    setCollapseOpen(!collapseOpen);
  };

  return (
    <NavbarContainer
      className={
        !sidebarState
          ? classnames(color, "navbar-sidebar-open")
          : classnames(color, "navbar-sidebar-closed")
      }
      expand="lg"
      fixed="top"
      $notificationbaropen={shouldShowNotificationBar({
        subscriptionStatus,
        cancellationDate,
        role,
        isEnterprise,
        location,
      })}
    >
      {shouldShowNotificationBar({
        subscriptionStatus,
        cancellationDate,
        role,
        isEnterprise,
        location,
      }) ? (
        <TrialEndBar
          subscriptionStatus={subscriptionStatus}
          trialEndDate={trialEndDate}
          cancellationDate={cancellationDate}
          orgName={selectedOrganization && selectedOrganization.name}
          history={history}
        />
      ) : null}
      <Container fluid className="">
        <NavbarWrapper className="navbar-wrapper">
          <NavbarToggle className="navbar-toggle">
            <NavbarTogglerButton
              className="navbar-toggler"
              type="button"
              onClick={toggleSidebar}
            >
              <NavbarTogglerBar className="navbar-toggler-bar bar1" />
              <NavbarTogglerBar className="navbar-toggler-bar bar2" />
              <NavbarTogglerBar className="navbar-toggler-bar bar3" />
            </NavbarTogglerButton>
          </NavbarToggle>
        </NavbarWrapper>
      </Container>
    </NavbarContainer>
  );
};

export default AdminNavbar;

{
  /* <NavbarTogglerButton
          aria-controls="navigation-index"
          aria-expanded={collapseOpen}
          aria-label="Toggle navigation"
          className="navbar-toggler"
          data-toggle="collapse"
          type="button"
          onClick={toggleCollapse}
        >
          <NavbarTogglerBar className="navbar-toggler-bar navbar-kebab" />
          <NavbarTogglerBar className="navbar-toggler-bar navbar-kebab" />
          <NavbarTogglerBar className="navbar-toggler-bar navbar-kebab" />
        </NavbarTogglerButton> */
}
{
  /* {organizations &&
          organizations.list &&
          organizations.list.length > 0 && (
            <OrganizationSection
              collapseOpen={collapseOpen}
              history={history}
              activeOrgsList={activeOrgsList}
              archivedOrgsList={archivedOrgsList}
              selectedOrgId={selectedOrganization && selectedOrganization.id}
              selectedOrgName={selectedOrgName}
              selectedOrgColor={selectedOrgColor}
              selectOrg={selectOrg}
              isActive={
                organizations &&
                organizations.selectedOrganization &&
                organizations.selectedOrganization.is_active
              }
            />
          )} */
}
