import {
  SIGN_UP_REQUEST,
  SIGN_UP_RESET,
  CHECK_TOKEN,
  FROM_ADVERTISE,
  CLEAR_ADVERTISE_INFO,
} from "../../modules/constants";

// ------------------------------------
// Actions
// ------------------------------------
export function signUp(value) {
  return {
    type: SIGN_UP_REQUEST,
    payload: value,
  };
}

export function resetSignUp() {
  return {
    type: SIGN_UP_RESET,
  };
}

export function checkToken() {
  return {
    type: CHECK_TOKEN,
  };
}

export function fromAdvertise(value) {
  return {
    type: FROM_ADVERTISE,
    payload: value,
  };
}

export function clearAdvertiseInfo(value) {
  return {
    type: CLEAR_ADVERTISE_INFO,
    payload: value,
  };
}
