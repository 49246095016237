import {
  SIGN_UP_REQUEST,
  SIGN_UP_REQUEST_SUCCESS,
  SIGN_UP_REQUEST_FAILURE,
  SIGN_UP_RESET,
  CHECK_TOKEN,
  CHECK_TOKEN_FAILURE,
  CHECK_TOKEN_SUCCESS,
  FROM_ADVERTISE,
  CLEAR_ADVERTISE_INFO,
} from "../../modules/constants";
import { createNotification } from "../../modules/notificationManager";
import { clearAdvertiseInfo, fromGoogleAd } from "./signUpActions";

// ------------------------------------
// Action handler methods
// ------------------------------------
export const signUpRequestHandler = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const signUpRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 201) {
    return {
      ...state,
      isLoading: false,
      redirect: true,
      token: data.data.token,
      userData: data.data,
    };
  }
  return {
    ...state,
    isLoading: false,
  };
};

export const signUpRequestFailureHandler = (state, action) => {
  if (action.payload.response === undefined) {
    return {
      ...state,
      isLoading: false,
      redirect: false,
    };
  }
  const {
    payload: {
      response: { data },
    },
  } = action;

  if (data.email !== undefined) {
    createNotification("error", data.email[0], 3000);
  } else if (data.username !== undefined) {
    createNotification("error", data.username[0], 3000);
  }
  return {
    ...state,
    isLoading: false,
    redirect: false,
  };
};

export const signUpResetHandler = (state, action) => {
  return {
    ...state,
    isLoading: false,
    redirect: false,
    token: null,
    userData: null,
  };
};

export const checkTokenRequestHandler = (state, action) => {
  return {
    ...state,
  };
};

export const checkTokenRequestSuccessHandler = (state, action) => {
  return {
    ...state,
    isLoading: false,
  };
};

export const checkTokenRequestFailureHandler = (state, action) => {
  return {
    ...state,
    isLoading: false,
    redirect: false,
  };
};

export const fromAdvertiseHandler = (state, action) => {
  const {
    payload: { advertise },
  } = action;
  return {
    ...state,
    advertise: advertise,
  };
}

export const clearAdvertiseInfoHandler = (state, action) => {
  return {
    ...state,
    advertise: "",
  };
}

// Action Handlers
const ACTION_HANDLERS = {
  [SIGN_UP_REQUEST]: signUpRequestHandler,
  [SIGN_UP_REQUEST_SUCCESS]: signUpRequestSuccessHandler,
  [SIGN_UP_REQUEST_FAILURE]: signUpRequestFailureHandler,

  [SIGN_UP_RESET]: signUpResetHandler,

  [CHECK_TOKEN]: checkTokenRequestHandler,
  [CHECK_TOKEN_SUCCESS]: checkTokenRequestSuccessHandler,
  [CHECK_TOKEN_FAILURE]: checkTokenRequestFailureHandler,

  [FROM_ADVERTISE]: fromAdvertiseHandler,
  [CLEAR_ADVERTISE_INFO]: clearAdvertiseInfoHandler,
};

// default initial state
const initialState = {
  isLoading: false,
  redirect: false,
  token: null,
  userData: null,
  advertise: "",
};

export default function signUpReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
