import { useEffect, useState } from "react";
import { SettingsTabContainer } from "./timesheetSettingsStyles";
import {
  PageTitle,
  ContentHeader,
  Container,
} from "../../../styledComponents/common";
import {
  TabButtonContainer,
  TabButton,
} from "../../../styledComponents/buttons";

import BackButtonComponent from "../../../components/BackButtonComponent/BackButtonComponent";

import ManualTimeSettings from "./ManualTimeSettings/manualTimeSettingsContainer";
import TimesheetApprovalSettings from "./TimesheetApprovalSettings/timesheetApprovalSettingsContainer";

const TimesheetSettings = (props) => {
  const [activeTab, setActiveTab] = useState("manual");

  const { history, selectedOrganization } = props;

  useEffect(() => {
    if (
      selectedOrganization?.role &&
      selectedOrganization.role !== "owner" &&
      selectedOrganization.role !== "admin"
    ) {
      setActiveTab("approval");
    }
  }, [selectedOrganization?.role]);

  const handleTabSelect = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <div className="content">
      <PageTitle>Timesheet Settings</PageTitle>
      <ContentHeader>
        <BackButtonComponent
          onClick={() => history.push("/user/settings")}
          subTitle="Back to settings"
        />
      </ContentHeader>

      <Container padding="0">
        {selectedOrganization &&
        (selectedOrganization.role === "owner" ||
          selectedOrganization.role === "admin") ? (
          <SettingsTabContainer>
            <TabButtonContainer>
              <TabButton
                selected={activeTab}
                index="manual"
                onClick={() => handleTabSelect("manual")}
              >
                Manual Time
              </TabButton>
              <TabButton
                selected={activeTab}
                index="approval"
                onClick={() => handleTabSelect("approval")}
              >
                Timesheet Approval
              </TabButton>
            </TabButtonContainer>
          </SettingsTabContainer>
        ) : null}

        {activeTab && activeTab === "approval" ? (
          <TimesheetApprovalSettings history={history} />
        ) : (
          <ManualTimeSettings />
        )}
      </Container>
    </div>
  );
};

export default TimesheetSettings;
