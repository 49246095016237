import { connect } from "react-redux";

import Members from "./Members";

// Actions
import {
  getMembersList,
  getInvitedMembersList,
  removeMember,
  inviteMember,
  deleteMemberInvitation,
} from "./membersActions";
import { updateMemberwiseTimesheetApprovalSettings } from "../Settings/TimesheetSettings/timesheetSettingsActions";

// Store props to map with current state
const mapStateToProps = (state) => ({
  isLoading: state.member.isLoading,
  deleteInvitationLoading: state.member.deleteInvitationLoading,
  deleteMemberLoading: state.member.deleteMemberLoading,
  selectedOrganization: state.organization.selectedOrganization,
  membersList: state.member.members,
  invitedMemberList: state.member.invitedMembersList,

  // pagination
  totalMembersCount: state.member.totalMembersCount,
  pageSize: state.member.pageSize,
  reloadInvitedMembers: state.member.reloadInvitedMembers,
});

// Actions prop to dispatch
const mapDispatchToProps = (dispatch) => ({
  getMembersList: (details) => dispatch(getMembersList({ ...details })),
  getInvitedMembersList: (details) =>
    dispatch(getInvitedMembersList({ ...details })),
  inviteMember: (details) => dispatch(inviteMember({ ...details })),
  removeMember: (details) => dispatch(removeMember({ ...details })),
  deleteMemberInvitation: (details) =>
    dispatch(deleteMemberInvitation({ ...details })),

  // settings
  updateMemberwiseTimesheetApprovalSettings: (details) =>
    dispatch(updateMemberwiseTimesheetApprovalSettings({ ...details })),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(Members);
