import { useEffect } from "react";
import styled from "styled-components";
import { css } from "@emotion/core";
import PulseLoader from "react-spinners/PulseLoader";
import TagManager from "react-gtm-module";

const customCss = css`
  margin: 5px;
  text-align: center;
`;

const VerifyContainer = styled.div`
  height: 100%;
  background: #f7fafb;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const VerifyText = styled.p`
  font-size: 28px;
`;

const TextLoader = (props) => {
  return (
    <div className="sweet-loading">
      <PulseLoader
        size={8}
        color={"#2f394e"}
        loading={props.loading}
        css={customCss}
      />
    </div>
  );
};
const OnboardingLoader = (props) => {
  const tagManagerArgs = {
    gtmId: "GTM-NH9X5RJV",
  };

  const { location, advertise } = props;

  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
    setTimeout(() => {
      props.history.push({
        pathname: "/user/dashboard",
        state: {
          message: "onboard",
          advertise: location.state && location.state.advertise,
        },
      });
    }, 5000);
  }, []);

  useEffect(() => {
    if (
      location.state &&
      location.state.message &&
      location.state.message === "onboard" &&
      location.state.advertise &&
      location.state.advertise === "google"
    ) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "onboardingLoader",
        page: location.pathname + location.search, // Send the current URL as part of the event
      });
    }
  }, [location]);

  return (
    <VerifyContainer>
      <VerifyText>Sit tight, we are getting things ready for you</VerifyText>
      <TextLoader loading />
    </VerifyContainer>
  );
};

export default OnboardingLoader;
