import { useState, useEffect, Fragment, useMemo } from "react";
import moment from "moment-timezone";
import {
  CardTitle,
  TableText,
  Container,
  RoundMiniImage,
  CenteredTooltip,
  CommonFlex,
  ColoredText,
  CommonImage,
} from "../../../styledComponents/common";
import { ActivityTextSection } from "../../../styledComponents/members";
import {
  WhiteButton,
  ColoredButton,
  PrimaryButton,
} from "../../../styledComponents/buttons";
import {
  AssigneesManageImgTextGrid,
  AssigneesManageRoundImage,
} from "../../../styledComponents/createProject";
import {
  CheckBox,
  ButtonContainer,
  AwaitingTableRow,
  CheckBoxTextContainer,
  PaymentButtonContainer,
  TimesheetBoxContainer,
  TimesheetBox,
  RejectButton,
  RejectButtonIcon,
  IdleTimeDurationDiv,
  IdleTimeDurationBox,
} from "./awaitingApprovalStyles";
import { TableItem } from "../../../styledComponents/teams";
import { toHHMMSS } from "../../../utils/helper";

import RejectModal from "./RejectModal";

import ComponentCircleLoader from "../../../components/Loaders/ComponentCircleLoader";
import NoDataComponent from "../../../components/NoDataComponent/NoDataComponent";
import ButtonTextLoader from "../../../components/Loaders/ButtonTextLoader";
import FirstRoundLetterComp from "../../../components/FirstRoundLetter/FirstRoundLetter";

import tickIcon from "../../../assets/img/icons/tick.svg";
import tickWhite from "../../../assets/img/icons/tick_2.svg";
import acceptedIcon from "../../../assets/img/icons/verified.svg";
import rejectedIcon from "../../../assets/img/icons/cross_red_bg.svg";
import notAllowedIcon from "../../../assets/img/icons/not-allowed.svg";

export const CommImageNameComp = ({ size, imgSource, name }) => (
  <AssigneesManageImgTextGrid>
    <AssigneesManageRoundImage imageSize={size}>
      <RoundMiniImage src={imgSource} alt="" imageSize={size} />
    </AssigneesManageRoundImage>
    <TableText name>{name}</TableText>
  </AssigneesManageImgTextGrid>
);

const AwaitingTable = (props) => {
  const [awaitingApprovalsData, setAwaitingApprovalsData] = useState([]);
  const [hoveredId, setHoveredId] = useState(-1);

  const [rejectModalOpen, setRejectModalOpen] = useState(false);

  const [timesheetData, setTimesheetData] = useState(null);

  const {
    seeMoreIsLoading,
    approveIsLoading,
    rejectIsLoading,
    awaitingList,
    organizationMembersList,
    selectedOrganization,
    approveOrRejectTimesheets,
    handleSeeMore,

    // pagination
    paginationSize,
    allLoadedDates,
    clearLoadMoreTimesheetApprovalList,
  } = props;

  useEffect(() => {
    return () => {
      clearLoadMoreTimesheetApprovalList();
    };
  }, []);

  useEffect(() => {
    if (
      awaitingList &&
      awaitingList.size > 0 &&
      organizationMembersList &&
      organizationMembersList.length > 0
    ) {
      const awaitingListData = Array.from(awaitingList);
      const approvalList = [];
      awaitingListData.forEach((entry) => {
        const date = entry[0];
        const userMap = entry[1];

        const dateData = {
          date,
          selected: false,
          selectedCount: 0,
        };
        const memberData = [];
        Array.from(userMap).forEach((userEntry) => {
          const userId = userEntry[0];
          const userData = userEntry[1];

          const data = {
            id: userId,
            ...userData,
            selected: false,
          };
          organizationMembersList.some((member) => {
            if (member.id === userId) {
              data.user = {
                id: member.id,
                name: member.name,
                avatar: member.avatar ?? null,
                color: member.color ?? null,
              };
              return true;
            }
            return false;
          });
          memberData.push({ ...data });
        });
        dateData.membersData = [...memberData];

        approvalList.push({ ...dateData });
      });
      setAwaitingApprovalsData([...approvalList]);
    } else if (awaitingList && awaitingList.size === 0) {
      setAwaitingApprovalsData([]);
    }
  }, [awaitingList, organizationMembersList]);

  const onHoverChange = (id) => {
    setHoveredId(id);
  };

  const toggleRejectModal = () => {
    if (rejectModalOpen) {
      setTimesheetData(null);
    }
    setRejectModalOpen(!rejectModalOpen);
  };

  const handleTimesheetClick = (timesheetData, userId, date) => {
    if (selectedOrganization && selectedOrganization.role !== "member") {
      setTimesheetData({ userId, date, ...timesheetData });
      setRejectModalOpen(true);
    }
  };

  const onSinglePersonSelect = (dateIndex, memberIndex, value) => {
    const list = JSON.parse(JSON.stringify(awaitingApprovalsData));
    list[dateIndex].membersData[memberIndex].selected = value;

    if (value) {
      list[dateIndex].selectedCount += 1;
    } else {
      list[dateIndex].selectedCount -= 1;
      list[dateIndex].selected = false;
    }
    setAwaitingApprovalsData([...list]);
  };
  const onSingleDateAllPersonSelect = (dateIndex, value) => {
    const list = JSON.parse(JSON.stringify(awaitingApprovalsData));
    list[dateIndex].selected = value;
    list[dateIndex].membersData.forEach((member) => {
      member.selected = value;
    });
    list[dateIndex].selectedCount = value
      ? list[dateIndex].membersData.length
      : 0;

    setAwaitingApprovalsData([...list]);
  };

  const handleRejectPartialTimesheet = (timesheetData, rejectDuration) => {
    if (selectedOrganization && selectedOrganization.id && timesheetData) {
      const { id, userId, date, adjustedEnd } = timesheetData;
      const start = adjustedEnd - rejectDuration;
      const payload = {
        organization_id: selectedOrganization.id,
        action: "reject",
        date,
        data: [
          {
            timesheet_id: id,
            start: start,
            end: adjustedEnd,
            user_id: userId,
            is_approved: false,
          },
        ],
      };
      approveOrRejectTimesheets(payload);
    }
    setTimeout(() => {
      setRejectModalOpen(false);
    }, 800);
  };

  const handleApproveOrRejectMultipleTimesheets = (dateIndex, date, action) => {
    if (selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization.id,
        action,
        date,
      };
      const list = JSON.parse(JSON.stringify(awaitingApprovalsData));
      const approvalData = [];
      list[dateIndex].membersData.forEach((member) => {
        if (
          member.selected &&
          member.acceptedDuration + member.rejectedDuration < member.duration
        ) {
          member.timesheets.forEach((timesheet) => {
            if (
              !timesheet.completed &&
              timesheet.adjustedEnd - timesheet.adjustedStart > 0
            ) {
              approvalData.push({
                timesheet_id: timesheet.id,
                start: timesheet.adjustedStart,
                end: timesheet.adjustedEnd,
                user_id: member.id,
                is_approved: action === "approve",
              });
            }
          });
        }
      });

      if (approvalData && approvalData.length > 0) {
        payload.data = [...approvalData];
        approveOrRejectTimesheets(payload);
      }
    }
  };

  return (
    <>
      {awaitingApprovalsData && awaitingApprovalsData.length > 0 ? (
        <>
          <RejectModal
            isOpen={rejectModalOpen}
            toggle={toggleRejectModal}
            timesheetData={timesheetData}
            handleRejectPartialTimesheet={handleRejectPartialTimesheet}
            rejectIsLoading={rejectIsLoading}
          />
          {awaitingApprovalsData.map(
            ({ date, selected, selectedCount, membersData }, dateIndex) => {
              return (
                <Fragment key={date}>
                  <ButtonContainer index={dateIndex}>
                    <WhiteButton
                      type="cancel"
                      bgColor="#f8f9fc"
                      borderColor="#c2cce1"
                      cursor="auto"
                    >
                      {moment(date).format("ddd, MMM DD, YYYY")}
                    </WhiteButton>
                  </ButtonContainer>
                  <AwaitingTableRow header>
                    <TableItem>
                      <CheckBoxTextContainer
                        columns={
                          selectedOrganization &&
                          selectedOrganization.role === "member" &&
                          "auto"
                        }
                      >
                        {selectedOrganization &&
                          selectedOrganization.role !== "member" && (
                            <CheckBox
                              bgColor={selected}
                              onClick={() =>
                                onSingleDateAllPersonSelect(
                                  dateIndex,
                                  !selected
                                )
                              }
                            >
                              {selected && (
                                <img
                                  src={tickWhite}
                                  alt=""
                                  width="16px"
                                  height="16px"
                                />
                              )}
                            </CheckBox>
                          )}

                        <TableText>Member Name</TableText>
                      </CheckBoxTextContainer>
                    </TableItem>
                    <TableItem>
                      <TableText>Time Worked</TableText>
                    </TableItem>
                    <TableItem buttonPadding={selectedCount > 0}>
                      <CommonFlex
                        padding="0 0 0 120px"
                        justifyContent="space-between"
                      >
                        <TableText>Timesheets</TableText>
                        {selectedCount > 0 && (
                          <CommonFlex gap="12px">
                            <WhiteButton
                              type="delete"
                              height="36px"
                              padding={rejectIsLoading && "0 10px"}
                              onClick={() =>
                                handleApproveOrRejectMultipleTimesheets(
                                  dateIndex,
                                  date,
                                  "reject"
                                )
                              }
                            >
                              {rejectIsLoading ? (
                                <ButtonTextLoader
                                  fontSize="13px"
                                  loadingText="Rejecting"
                                  color="#fe5969"
                                  fontWeight="500"
                                />
                              ) : (
                                "Reject"
                              )}
                            </WhiteButton>
                            <PrimaryButton
                              height="36px"
                              padding={approveIsLoading && "0 10px"}
                              onClick={() =>
                                handleApproveOrRejectMultipleTimesheets(
                                  dateIndex,
                                  date,
                                  "approve"
                                )
                              }
                            >
                              {approveIsLoading ? (
                                <ButtonTextLoader
                                  fontSize="13px"
                                  loadingText="Approving"
                                />
                              ) : (
                                "Approve"
                              )}
                            </PrimaryButton>
                          </CommonFlex>
                        )}
                      </CommonFlex>
                    </TableItem>
                    {/* <TableItem buttonPadding>
                  </TableItem> */}
                  </AwaitingTableRow>
                  {membersData.map((userData, memberIndex) => {
                    return (
                      <AwaitingTableRow
                        key={userData.id}
                        style={{ position: "relative" }}
                      >
                        <TableItem>
                          <CheckBoxTextContainer
                            columns={
                              selectedOrganization &&
                              selectedOrganization.role === "member" &&
                              "auto"
                            }
                          >
                            {selectedOrganization &&
                              selectedOrganization.role !== "member" && (
                                <>
                                  {userData.acceptedDuration +
                                    userData.rejectedDuration >=
                                  userData.duration ? (
                                    <CommonImage
                                      src={notAllowedIcon}
                                      alt=""
                                      size="18px"
                                      margin="0 0 0 2px"
                                    />
                                  ) : (
                                    <CheckBox
                                      onClick={() =>
                                        onSinglePersonSelect(
                                          dateIndex,
                                          memberIndex,
                                          userData.selected ? false : true
                                        )
                                      }
                                    >
                                      {userData.selected && (
                                        <img
                                          src={tickIcon}
                                          alt="tick"
                                          width="20px"
                                          height="20px"
                                        />
                                      )}
                                    </CheckBox>
                                  )}
                                </>
                              )}
                            {userData.user && userData.user.avatar ? (
                              <CommImageNameComp
                                name={userData.user && userData.user.name}
                                imgSource={
                                  userData.user && userData.user.avatar
                                }
                                size="36px"
                              />
                            ) : (
                              <CommonFlex gap="14px">
                                <FirstRoundLetterComp
                                  text={userData.user && userData.user.name}
                                  backColor={memberIndex}
                                  color={userData.user && userData.user.color}
                                />
                                <TableText name>
                                  {userData.user && userData.user.name}
                                </TableText>
                              </CommonFlex>
                            )}
                          </CheckBoxTextContainer>
                        </TableItem>
                        <TableItem>
                          <ActivityTextSection>
                            <CommonFlex gap="10px">
                              <TableText name>
                                {toHHMMSS(userData.duration)}
                              </TableText>
                              {userData.idleTimeDuration &&
                              userData.idleTimeDuration > 0 ? (
                                <IdleTimeDurationDiv>
                                  <IdleTimeDurationBox padding="3px 5px">
                                    {toHHMMSS(userData.idleTimeDuration)}
                                  </IdleTimeDurationBox>
                                  <CenteredTooltip
                                    right="calc(100% + 4px)"
                                    top="0"
                                    left="unset"
                                  >
                                    Idle Time
                                  </CenteredTooltip>
                                </IdleTimeDurationDiv>
                              ) : null}
                            </CommonFlex>

                            <ActivityTextSection gap="4px">
                              {userData.acceptedDuration &&
                              userData.acceptedDuration > 0 &&
                              userData.acceptedDuration +
                                userData.rejectedDuration <
                                userData.duration ? (
                                <ColoredText type="success" fontSize="10.5px">
                                  Accepted:{" "}
                                  {toHHMMSS(userData.acceptedDuration)}
                                </ColoredText>
                              ) : null}
                              {userData.rejectedDuration &&
                              userData.rejectedDuration > 0 &&
                              userData.acceptedDuration +
                                userData.rejectedDuration <
                                userData.duration ? (
                                <ColoredText type="error" fontSize="10.5px">
                                  Rejected:{" "}
                                  {toHHMMSS(userData.rejectedDuration)}
                                </ColoredText>
                              ) : null}
                            </ActivityTextSection>
                          </ActivityTextSection>
                        </TableItem>
                        {/* <TableItem> */}
                        {userData.acceptedDuration +
                          userData.rejectedDuration >=
                        userData.duration ? (
                          <TableItem padding="0 0 0 120px">
                            <CommonFlex>
                              {userData.acceptedDuration &&
                              userData.acceptedDuration > 0 ? (
                                <CommonFlex gap="6px">
                                  <TableText name>
                                    {toHHMMSS(userData.acceptedDuration)}
                                  </TableText>
                                  <CommonImage
                                    src={acceptedIcon}
                                    alt=""
                                    size="15px"
                                  />
                                </CommonFlex>
                              ) : null}
                              {userData.rejectedDuration &&
                              userData.rejectedDuration > 0 ? (
                                <CommonFlex gap="6px">
                                  <TableText name>
                                    {toHHMMSS(userData.rejectedDuration)}
                                  </TableText>
                                  <CommonImage
                                    src={rejectedIcon}
                                    alt=""
                                    size="15px"
                                  />
                                </CommonFlex>
                              ) : null}
                            </CommonFlex>
                          </TableItem>
                        ) : selectedOrganization &&
                          selectedOrganization.timezone ? (
                          <TimesheetBoxContainer>
                            {userData.timesheets.map((timesheet, index) => {
                              if (!timesheet.completed) {
                                return (
                                  <TimesheetBox
                                    // onMouseEnter={() => onHoverChange(timesheet.id)}
                                    // onMouseLeave={() => onHoverChange(-1)}
                                    cursor={
                                      selectedOrganization &&
                                      selectedOrganization.role === "member" &&
                                      "auto"
                                    }
                                    onClick={() =>
                                      handleTimesheetClick(
                                        timesheet,
                                        userData.id,
                                        date
                                      )
                                    }
                                    index={index}
                                    duration={
                                      timesheet.endTimestamp -
                                      timesheet.adjustedStart
                                    }
                                    rejectedDuration={
                                      timesheet.rejectedDuration
                                    }
                                  >
                                    <CenteredTooltip
                                      right="calc(100% + 4px)"
                                      top="3px"
                                      left="unset"
                                    >
                                      {`${moment
                                        .unix(timesheet.adjustedStart)
                                        .tz(selectedOrganization.timezone)
                                        .format("LT")}-${moment
                                        .unix(timesheet.endTimestamp)
                                        .tz(selectedOrganization.timezone)
                                        .format("LT")}`}
                                    </CenteredTooltip>
                                    {/* {hoveredId === timesheet.id && (
                                      <RejectButton
                                        onClick={() =>
                                          toggleRejectPopup(timesheet.id)
                                        }
                                      >
                                        <RejectButtonIcon />
                                      </RejectButton>
                                    )} */}
                                  </TimesheetBox>
                                );
                              }
                              return null;
                            })}
                          </TimesheetBoxContainer>
                        ) : null}

                        {/* </TableItem> */}
                      </AwaitingTableRow>
                    );
                  })}
                  {seeMoreIsLoading && seeMoreIsLoading === date ? (
                    <ButtonTextLoader
                      fontSize="13px"
                      color="#20bead"
                      justifyContent="flex-start"
                      margin="5px 0 5px 40px"
                    />
                  ) : membersData.length >= paginationSize &&
                    !(allLoadedDates && allLoadedDates.hasOwnProperty(date)) ? (
                    <ColoredText
                      primary
                      hover
                      fontSize="13px"
                      fontWeight="600"
                      margin="10px 0 10px 40px"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        handleSeeMore(date, membersData.length, "awaiting")
                      }
                    >
                      See More
                    </ColoredText>
                  ) : null}
                </Fragment>
              );
            }
          )}
        </>
      ) : (
        <NoDataComponent title="Sorry! No timesheet found to be approved." />
      )}
    </>
  );
};

export default AwaitingTable;
