// Effects
import { all, call, put, takeLatest } from "redux-saga/effects";

// Service
import { defaultApi } from "../../utils/axiosApi";
// Constants
import {
  GET_MEMBERS,
  GET_MEMBERS_SUCCESS,
  GET_MEMBERS_FAILURE,
  GET_INVITED_MEMBERS,
  GET_INVITED_MEMBERS_SUCCESS,
  GET_INVITED_MEMBERS_FAILURE,
  INVITE_MEMBER,
  INVITE_MEMBER_SUCCESS,
  INVITE_MEMBER_FAILURE,
  DELETE_MEMBER_INVITATION,
  DELETE_MEMBER_INVITATION_SUCCESS,
  DELETE_MEMBER_INVITATION_FAILURE,
  GET_MEMBER_DETAILS,
  GET_MEMBER_DETAILS_SUCCESS,
  GET_MEMBER_DETAILS_FAILURE,
  GET_MEMBER_WEEKLY_EARNING,
  GET_MEMBER_WEEKLY_EARNING_SUCCESS,
  GET_MEMBER_WEEKLY_EARNING_FAILURE,
  GET_MEMBER_MONTHLY_EARNING,
  GET_MEMBER_MONTHLY_EARNING_SUCCESS,
  GET_MEMBER_MONTHLY_EARNING_FAILURE,
  UPDATE_MEMBER,
  UPDATE_MEMBER_SUCCESS,
  UPDATE_MEMBER_FAILURE,
  REMOVE_MEMBER,
  REMOVE_MEMBER_SUCCESS,
  REMOVE_MEMBER_FAILURE,
  ARCHIVE_MEMBERS,
  ARCHIVE_MEMBERS_SUCCESS,
  ARCHIVE_MEMBERS_FAILURE,

  // payment configuration
  SET_PAYMENT_CONFIGURATION,
  SET_PAYMENT_CONFIGURATION_SUCCESS,
  SET_PAYMENT_CONFIGURATION_FAILURE,
  UPDATE_PAYMENT_CONFIGURATION,
  UPDATE_PAYMENT_CONFIGURATION_SUCCESS,
  UPDATE_PAYMENT_CONFIGURATION_FAILURE,
} from "../../modules/constants";

//Handle login request

export function* getMembersList({ payload }) {
  const { organization_id, page, name } = payload;
  try {
    const url = `${organization_id}/members/${page ? `?page=${page}` : ``}${
      name ? (page ? `&name=${name}` : `?name=${name}`) : ``
    }`;
    const method = "GET";
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_MEMBERS_SUCCESS,
      payload: { data: response, notify: "GET" },
    });
  } catch (err) {
    yield put({
      type: GET_MEMBERS_FAILURE,
      payload: err,
    });
  }
}

export function* getInvitedMembersList({ payload }) {
  const { organization_id } = payload;
  try {
    const url = `${organization_id}/members/invitations/`;
    const method = "GET";
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_INVITED_MEMBERS_SUCCESS,
      payload: { data: response, notify: "GET" },
    });
  } catch (err) {
    yield put({
      type: GET_INVITED_MEMBERS_FAILURE,
      payload: err,
    });
  }
}

export function* inviteMember({ payload }) {
  const { organization_id, resend, ...details } = payload;
  try {
    const url = `${organization_id}/members/invitations/`;
    const method = "POST";
    const response = yield defaultApi(url, method, details);
    yield put({
      type: INVITE_MEMBER_SUCCESS,
      payload: { data: response, resend, notify: "POST" },
    });
  } catch (err) {
    yield put({
      type: INVITE_MEMBER_FAILURE,
      payload: err,
    });
  }
}

export function* deleteMemberInvitation({ payload }) {
  const { organization_id, invitation_id } = payload;
  try {
    const url = `${organization_id}/members/invitations/${invitation_id}/`;
    const method = "DELETE";
    const response = yield defaultApi(url, method);
    // yield call(getInvitedMembersList, { payload });
    yield put({
      type: DELETE_MEMBER_INVITATION_SUCCESS,
      payload: { ...payload, data: response, notify: "DELETE" },
    });
  } catch (err) {
    yield put({
      type: DELETE_MEMBER_INVITATION_FAILURE,
      payload: err,
    });
  }
}

export function* getMemberDetails({ payload }) {
  const { organization_id, user_id } = payload;
  try {
    const url = `${organization_id}/members/${user_id}/`;
    const method = "GET";
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_MEMBER_DETAILS_SUCCESS,
      payload: { data: response, notify: "GET" },
    });
  } catch (err) {
    yield put({
      type: GET_MEMBER_DETAILS_FAILURE,
      payload: err,
    });
  }
}

export function* getMemberWeeklyEarning({ payload }) {
  const { organization_id, user_id } = payload;
  try {
    const url = `${organization_id}/members/${user_id}/earnings/week/`;
    const method = "GET";
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_MEMBER_WEEKLY_EARNING_SUCCESS,
      payload: { data: response, notify: "GET" },
    });
  } catch (err) {
    yield put({
      type: GET_MEMBER_WEEKLY_EARNING_FAILURE,
      payload: err,
    });
  }
}

export function* getMemberMonthlyEarning({ payload }) {
  const { organization_id, user_id } = payload;
  try {
    const url = `${organization_id}/members/${user_id}/earnings/month/`;
    const method = "GET";
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_MEMBER_MONTHLY_EARNING_SUCCESS,
      payload: { data: response, notify: "GET" },
    });
  } catch (err) {
    yield put({
      type: GET_MEMBER_MONTHLY_EARNING_FAILURE,
      payload: err,
    });
  }
}

export function* updateMemberRequest({ payload }) {
  const { organization_id, user_id, ...details } = payload;
  try {
    const url = `${organization_id}/members/${user_id}/`;
    const method = "PATCH";
    const response = yield defaultApi(url, method, details);
    // yield call(getMemberDetails, { payload });
    yield put({
      type: UPDATE_MEMBER_SUCCESS,
      payload: { data: response, notify: method },
    });
  } catch (err) {
    yield put({
      type: UPDATE_MEMBER_FAILURE,
      payload: { error: err.response ? err.response.data : err.message },
    });
  }
}

export function* removeMemberRequest({ payload }) {
  const { organization_id, user_id } = payload;
  try {
    const url = `${organization_id}/members/${user_id}/`;
    const method = "DELETE";
    const response = yield defaultApi(url, method);
    yield call(getMembersList, { payload });
    yield put({
      type: REMOVE_MEMBER_SUCCESS,
      payload: { data: response, notify: method },
    });
  } catch (err) {
    yield put({
      type: REMOVE_MEMBER_FAILURE,
      payload: err,
    });
  }
}

export function* setPaymentConfigurationRequest({ payload }) {
  try {
    const url = `payroll/paymentsettings/create/`;
    const method = "POST";
    const response = yield defaultApi(url, method, payload);
    const detailsPayload = {
      organization_id: payload.organization_id,
      user_id: payload.user_id,
    };
    yield call(getMemberDetails, { payload: detailsPayload });
    yield put({
      type: SET_PAYMENT_CONFIGURATION_SUCCESS,
      payload: { data: response, notify: method },
    });
  } catch (err) {
    yield put({
      type: SET_PAYMENT_CONFIGURATION_FAILURE,
      payload: err,
    });
  }
}

export function* updatePaymentConfigurationRequest({ payload }) {
  //const { member_id, ...details } = payload;
  try {
    const url = `payroll/paymentsettings/create/`;
    const method = "PATCH";
    const response = yield defaultApi(url, method, payload);
    yield put({
      type: UPDATE_PAYMENT_CONFIGURATION_SUCCESS,
      payload: { data: response, notify: method },
    });
  } catch (err) {
    yield put({
      type: UPDATE_PAYMENT_CONFIGURATION_FAILURE,
      payload: err,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(GET_MEMBERS, getMembersList),
    takeLatest(GET_INVITED_MEMBERS, getInvitedMembersList),
    takeLatest(INVITE_MEMBER, inviteMember),
    takeLatest(DELETE_MEMBER_INVITATION, deleteMemberInvitation),
    takeLatest(GET_MEMBER_DETAILS, getMemberDetails),
    takeLatest(GET_MEMBER_WEEKLY_EARNING, getMemberWeeklyEarning),
    takeLatest(GET_MEMBER_MONTHLY_EARNING, getMemberMonthlyEarning),
    takeLatest(UPDATE_MEMBER, updateMemberRequest),
    takeLatest(REMOVE_MEMBER, removeMemberRequest),
    takeLatest(SET_PAYMENT_CONFIGURATION, setPaymentConfigurationRequest),
    takeLatest(UPDATE_PAYMENT_CONFIGURATION, updatePaymentConfigurationRequest),
  ]);
}
