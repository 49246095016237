import moment from "moment";

import {
  TrialEndBarWrapper,
  TrialEndBarContainer,
  TrialEndText,
} from "../subscriptionStyles";
import {
  ColoredButtonWithIcon,
  ButtonIcon,
} from "../../../styledComponents/buttons";

import crown from "../../../assets/img/common/crown.svg";
import { remainingDays } from "custom_modules/AdminNavbar/AdminNavbar";

const getTrialEndText = (props) => {
  const { subscriptionStatus, orgName, trialEndDate, cancellationDate } = props;

  switch (subscriptionStatus) {
    case "paused":
      return `Your current subscription is paused. Organization ${
        orgName ? `"${orgName}"` : ""
      } is archived.`;
    case "suspended":
      return `${
        trialEndDate && moment(trialEndDate).isBefore(moment(), "day")
          ? `Your trial ended on ${moment(trialEndDate).format(
              "MMMM D, YYYY"
            )}.`
          : "Your subscription is suspended."
      } Organization ${orgName ? `"${orgName}"` : ""} is archived.`;
    case "cancelled":
      if (remainingDays(cancellationDate) <= 14) {
        const daysRemaining = remainingDays(cancellationDate);
        return `${
          daysRemaining === 1 ? "Only 1 day" : `${daysRemaining} days`
        } remaining. Your current subscription will end ${
          daysRemaining === 1
            ? "today"
            : `on ${moment(cancellationDate).format("MMM DD, YYYY")}`
        }. Please upgrade your subscription to continue using Apploye.`;
      }
      return `Your current subscription is cancelled. Organization ${
        orgName ? `"${orgName}"` : ""
      } is archived.`;
    case "trialing":
      if (trialEndDate) {
        return `Your trial is ending on ${moment(trialEndDate).format(
          "MMMM D, YYYY"
        )}`;
      }
      return "Your trial is ending soon. Please upgrade your subscription to continue using Apploye.";
    default:
      return "";
  }
};

const TrialEndBar = (props) => {
  return (
    <TrialEndBarWrapper show>
      {/* = {props.subscriptionStatus == "cancelled" && remainingDays(props.subscriptionStatus)>14}> */}
      <TrialEndBarContainer status={props.subscriptionStatus}>
        <TrialEndText>{getTrialEndText(props)}</TrialEndText>
        <ColoredButtonWithIcon
          height="34px"
          margin="0 0 0 15px"
          padding="0 20px"
          type={
            (props.subscriptionStatus === "suspended" ||
              props.subscriptionStatus === "paused" ||
              props.subscriptionStatus === "cancelled") &&
            "black"
          }
          status={props.subscriptionStatus}
          onClick={() => {
            if (props.subscriptionStatus === "paused") {
              props.history.push("/user/current-plan");
            } else {
              props.history.push("/user/subscription");
            }
          }}
        >
          {props.subscriptionStatus !== "paused" && (
            <ButtonIcon src={crown} alt="" size="17px" />
          )}

          {props.subscriptionStatus === "paused" ? "View Details" : "UPGRADE"}
        </ColoredButtonWithIcon>
      </TrialEndBarContainer>
    </TrialEndBarWrapper>
  );
};

export default TrialEndBar;

// `10 days remaining. Your current subscription will end on Oct 30, 2024. Please upgrade your subscription.`
// .format('MMM DD, YYYY')
