import { useState, useEffect, useMemo } from "react";
import Select from "../../components/ReactSelectDropdown";
import FilterDropDownStyle from "../../components/DropdownStyle/FilterDropDownStyle";

import {
  PageTitle,
  CommonGrid,
  HeaderContainer,
  CommonText,
  ColoredText,
  CommonFlex,
  FormAlert,
} from "../../styledComponents/common";
import {
  ColoredButtonWithIcon,
  ButtonIcon,
  PrimaryButton,
  WhiteButton,
} from "../../styledComponents/buttons";
import {
  TasksTableRow,
  AddTaskContainer,
  AddTaskInputAndProjectContainer,
  AddTaskInput,
} from "./tasksStyles";

import { checkUrl, checkHtml } from "../../utils/helper";

import ProjectDropdown from "../../components/CommonFilter/ProjectDropdown";

import TaskFilter from "./TaskFilter";
import TasksList from "./TasksListNew";

import TopPagination from "../../components/Pagination/TopPagination/TopPagination";
import BottomPagination from "../../components/Pagination/BottomPagination/BottomPagination";

import plusIcon from "../../assets/img/icons/plus_white.svg";

export const AddTaskSection = ({
  fromProject,
  projectData,
  handleNewTask,
  selectedOrganization,
  projectAddTask,
  noRadius,
  position,
  resetFilters,
  currentPageNumber,
}) => {
  const [newTaskInput, setNewTaskInput] = useState("");
  const [selectedProject, setSelectedProject] = useState(null);

  const [saveErrors, setSaveErrors] = useState({});

  useEffect(() => {
    if (fromProject && projectData && projectData.id) {
      setSelectedProject({
        value: projectData.id,
        label: projectData.name,
      });
    }
  }, [projectData]);

  const handleInputChange = (e) => {
    const { value } = e.target;
    setNewTaskInput(value);
    setSaveErrors({});
  };
  const onProjectSelect = (e) => {
    setSelectedProject(e);
  };

  const checkError = () => {
    let formIsValid = true;
    const saveErrors = {};
    if (checkUrl(newTaskInput)) {
      formIsValid = false;
      saveErrors["newTaskInput"] = "Task name can not contain url.";
    } else if (checkHtml(newTaskInput)) {
      formIsValid = false;
      saveErrors["newTaskInput"] = "Invalid task name.";
    }

    setSaveErrors(saveErrors);
    return formIsValid;
  };
  const handleAddTask = (project) => {
    if (checkError()) {
      handleNewTask("none");
      if (
        selectedOrganization &&
        selectedOrganization.id &&
        project &&
        project.value
      ) {
        const payload = {
          organization_id: selectedOrganization.id,
          project_id: project.value,
          projectName: project.label,
          name: newTaskInput.trim(),
          position,
          currentPageNumber,
        };
        if (
          selectedOrganization.role &&
          selectedOrganization.role === "member"
        ) {
          const userId = localStorage.getItem("user_id");
          if (userId) {
            payload.user_id = userId;
          }
        }
        payload.onClick = () => {
          resetFilters();
        };
        projectAddTask(payload);
        setNewTaskInput("");
      }
    }
  };
  const handleKeyPress = (e, project) => {
    if (e.key === "Enter") {
      handleAddTask(project);
    }
  };
  return (
    <>
      <AddTaskContainer radius={noRadius && "none"}>
        <AddTaskInputAndProjectContainer>
          <AddTaskInput
            type="text"
            placeholder="Write task name..."
            autoFocus
            value={newTaskInput}
            onChange={(e) => handleInputChange(e)}
            onKeyDown={(e) => handleKeyPress(e, selectedProject)}
          />
          {!fromProject && (
            <ProjectDropdown
              selectedOrganization={selectedOrganization}
              selectedProject={selectedProject}
              selectProject={onProjectSelect}
              style={FilterDropDownStyle({ height: "38x", width: "230px" })}
              menuPlacement="auto"
            />
          )}
        </AddTaskInputAndProjectContainer>
        <CommonFlex justifyContent="flex-end" gap="15px">
          <WhiteButton
            type="cancel"
            onClick={() => handleNewTask("none")}
            height="36px"
          >
            Cancel
          </WhiteButton>
          <PrimaryButton
            height="36px"
            disabled={!newTaskInput.trim() || !selectedProject}
            onClick={() => handleAddTask(selectedProject)}
          >
            Add Task
          </PrimaryButton>
        </CommonFlex>
      </AddTaskContainer>
      {saveErrors &&
        saveErrors["newTaskInput"] &&
        saveErrors["newTaskInput"] !== undefined && (
          <FormAlert margin="0 0 0 15px">
            {saveErrors["newTaskInput"]}
          </FormAlert>
        )}
    </>
  );
};

const Tasks = ({
  selectedOrganization,
  tasks,
  getTaskList,
  getOrganizationMembersList,
  organizationMembersList,
  getProjectAssigneeList,
  projectAssigneeList,
  projectIsLoading,
  projectAddTask,
  projectEditTask,
  projectDeleteTask,
  addTaskAssignee,
  deleteTaskAssignee,
  projectAssigneeIsLoading,
  taskIsLoading,
  loadingTaskId,
  loadingTaskProjectId,
  taskAssigneeIsLoading,
  loadingTaskAssigneeId,
  isLoading,

  // pagination
  totalTasksCount,
  pageSize,

  reloadTasks,
}) => {
  const [selectedProject, setSelectedProject] = useState("");
  const [selectedMember, setSelectedMember] = useState("");

  const [taskStatus, setTaskStatus] = useState({
    value: "to do",
    label: "To Do",
  });

  const [newTask, setNewTask] = useState(null);

  const [searchTerm, setSearchTerm] = useState("");
  const [currentSearchedTerm, setCurrentSearchedTerm] = useState("");

  // pagination
  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  const fetchTasks = () => {
    if (selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization.id,
        status: taskStatus.value,
        page: currentPageNumber,
      };
      if (searchTerm && searchTerm.trim()) {
        payload.name = searchTerm.toLowerCase().trim();
      }
      if (selectedProject && selectedProject.value) {
        payload.project_id = selectedProject.value;
      }
      if (selectedMember && selectedMember.id) {
        payload.user_id = selectedMember.id;
      }
      getTaskList(payload);
    }
  };

  useEffect(() => {
    fetchTasks();
  }, [
    taskStatus,
    currentSearchedTerm,
    selectedProject,
    selectedMember,
    currentPageNumber,
    reloadTasks,
  ]);

  useEffect(() => {
    setCurrentPageNumber(1);
  }, [taskStatus, searchTerm, selectedProject, selectedMember]);

  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id) {
      resetFilters();
    }
  }, [selectedOrganization]);

  const handleSearchByName = () => {
    if (selectedOrganization && selectedOrganization.id) {
      setCurrentPageNumber(1);
      if (searchTerm && searchTerm.trim()) {
        setCurrentSearchedTerm(searchTerm);
      } else {
        setCurrentSearchedTerm("");
      }
    }
  };

  const handleClearSearch = () => {
    if (
      selectedOrganization &&
      selectedOrganization.id &&
      currentSearchedTerm
    ) {
      setCurrentPageNumber(1);
      setSearchTerm("");
      setCurrentSearchedTerm("");
    }
  };

  const handlePageChange = (page) => {
    setCurrentPageNumber(page);
  };

  const selectProject = (e) => {
    setSelectedProject(e);
    setCurrentPageNumber(1);
    setSearchTerm("");
    setCurrentSearchedTerm("");
  };

  const selectMember = (person) => {
    setSelectedMember(person);
    setSearchTerm("");
    setCurrentSearchedTerm("");
    setCurrentPageNumber(1);
  };

  const clearMember = () => {
    setSelectedMember("");
    setSearchTerm("");
    setCurrentSearchedTerm("");
    setCurrentPageNumber(1);
  };

  const resetFilters = () => {
    setCurrentPageNumber(1);
    setSelectedProject("");
    setSelectedMember("");
    setSearchTerm("");
    setCurrentSearchedTerm("");
    setTaskStatus({
      value: "to do",
      label: "To Do",
    });
  };

  const handleNewTask = (value) => {
    setNewTask(value);
  };

  const onSearchTermChange = (e) => {
    const { value } = e.target;
    if (value !== "") {
      setSelectedMember("");
    }
    setSearchTerm(value);
  };

  const handleStatusChange = (e) => {
    setTaskStatus(e);
    setCurrentPageNumber(1);
  };

  return (
    <div className="content">
      <CommonGrid alignItem="center">
        <PageTitle>Tasks</PageTitle>
      </CommonGrid>
      <HeaderContainer>
        <TaskFilter
          selectedOrganization={selectedOrganization}
          getOrganizationMembersList={getOrganizationMembersList}
          organizationMembersList={organizationMembersList}
          getProjectAssigneeList={getProjectAssigneeList}
          projectAssigneeList={projectAssigneeList}
          selectedProject={selectedProject}
          selectProject={selectProject}
          selectedMember={selectedMember}
          selectMember={selectMember}
          clearMember={clearMember}
          searchTerm={searchTerm}
          onSearchTermChange={onSearchTermChange}
          taskStatus={taskStatus}
          handleStatusChange={handleStatusChange}
          handleSearchByName={handleSearchByName}
          handleClearSearch={handleClearSearch}
        />

        <ColoredButtonWithIcon onClick={() => handleNewTask("top")}>
          <ButtonIcon src={plusIcon} alt="" />
          Add Task
        </ColoredButtonWithIcon>
      </HeaderContainer>

      {newTask && newTask === "top" && (
        <AddTaskSection
          selectedOrganization={selectedOrganization}
          handleNewTask={handleNewTask}
          projectAddTask={projectAddTask}
          currentPageNumber={currentPageNumber}
          position="top"
          resetFilters={resetFilters}
        />
      )}

      {totalTasksCount && totalTasksCount > 0 && pageSize ? (
        <TopPagination
          itemName="tasks"
          totalCount={totalTasksCount}
          currentPage={currentPageNumber}
          onPageChange={(page) => handlePageChange(page)}
          pageSize={pageSize}
        />
      ) : null}
      <TasksList
        tasksList={tasks}
        selectedOrganization={selectedOrganization}
        projectAddTask={projectAddTask}
        currentPageNumber={currentPageNumber}
        editTask={projectEditTask}
        projectDeleteTask={projectDeleteTask}
        getTaskList={getTaskList}
        getProjectAssigneeList={getProjectAssigneeList}
        projectAssigneeList={projectAssigneeList}
        addTaskAssignee={addTaskAssignee}
        deleteTaskAssignee={deleteTaskAssignee}
        searchTerm={searchTerm}
        projectAssigneeIsLoading={projectAssigneeIsLoading}
        taskIsLoading={taskIsLoading}
        loadingTaskId={loadingTaskId}
        loadingTaskProjectId={loadingTaskProjectId}
        taskAssigneeIsLoading={taskAssigneeIsLoading}
        loadingTaskAssigneeId={loadingTaskAssigneeId}
        taskListLoading={isLoading}
        newTask={newTask}
        handleNewTask={handleNewTask}
        taskStatus={taskStatus}
        handleStatusChange={handleStatusChange}
      />
      {totalTasksCount && totalTasksCount > 0 && pageSize ? (
        <BottomPagination
          totalCount={totalTasksCount}
          currentPage={currentPageNumber}
          onPageChange={(page) => handlePageChange(page)}
          pageSize={pageSize}
        />
      ) : null}
    </div>
  );
};

export default Tasks;
