import { useState, useEffect, useRef, Fragment } from "react";
import styled from "styled-components";
import {
  RoundLetterSection,
  FirstWordRound,
  CommonText,
  TableText,
  ManagerBadge,
  ShowMoreAssigneeList,
  AssignListText,
} from "../../styledComponents/common";
import {
  AssigneeRoundImage,
  AssigneesManageImgTextGrid,
  AssigneesManageRoundImage,
  RoundPersonImage,
} from "../../styledComponents/createProject";
import { AssigneeRoundImageSection } from "../../styledComponents/ProjectsNew";
import AssigneeTooltip from "../../components/Tooltip/AssigneeTooltip";
import FirstRoundLetterComp from "../../components/FirstRoundLetter/FirstRoundLetter";

import Avatar5 from "../../assets/img/avatar_5.svg";
import Badge from "../../assets/img/icons/manager_badge.svg";

const TeamName = styled(TableText)`
  &:hover {
    text-decoration: underline;
  }
`;

export const CommImageNameComp = (props) => {
  return (
    <AssigneesManageImgTextGrid>
      <div style={{ position: `relative` }}>
        {props.personImg ? (
          <AssigneesManageRoundImage>
            {" "}
            {props.personImg}{" "}
          </AssigneesManageRoundImage>
        ) : (
          <FirstRoundLetterComp
            text={props.personName}
            backColor={props.index}
            color={props.personColor}
            size="40px"
            fontSize="14px"
          />
        )}

        {props.isManager ? (
          <ManagerBadge src={Badge} alt="manager-badge" width="25px" />
        ) : null}
      </div>
      <CommonText>{props.personName}</CommonText>
    </AssigneesManageImgTextGrid>
  );
};

export const CommFirstRoundLetterComp = ({
  text,
  backColor,
  size,
  fontSize,
  gap,
  color,
}) => {
  const colorList = [
    { color: "#9A96F9" },
    { color: "#F9D059" },
    { color: "#F9828B" },
    { color: "#73C0FF" },
  ];
  let selectedIndex = 0;
  if (backColor) {
    if (backColor < 4) {
      selectedIndex = backColor;
    } else {
      selectedIndex = backColor % 4;
    }
  }
  return (
    <RoundLetterSection gap={gap}>
      <FirstWordRound
        backColor={color ? color : colorList[selectedIndex].color}
        size={size}
        fontSize={fontSize}
      >
        {text.slice(0, 1).toUpperCase()}{" "}
      </FirstWordRound>
      <TeamName name> {text}</TeamName>
    </RoundLetterSection>
  );
};

export const CommMemberListComp = ({ memberList }) => {
  const [moreAssignee, setMoreAssignee] = useState(false);
  const moreRef1 = useRef(null);

  useOutsideClickHandler(moreRef1);

  function useOutsideClickHandler(moreRef1) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (moreRef1.current && !moreRef1.current.contains(event.target)) {
          setMoreAssignee(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [moreRef1]);
  }

  const showMoreAssignee = () => {
    setMoreAssignee(true);
  };
  return (
    <AssigneeRoundImageSection
      position={memberList.length - 1}
      style={{ marginRight: `auto` }}
    >
      {memberList.length > 3 && (
        <AssigneeRoundImage
          index={0}
          onClick={() => {
            showMoreAssignee();
          }}
          ref={moreRef1}
          bgColor="#c2cce1"
          size="36px"
        >
          {` +${memberList.length - 3} `}
        </AssigneeRoundImage>
      )}
      {memberList
        .filter((person, index) => index < 3)
        .map((filteredPerson, index) => (
          <AssigneeRoundImage
            key={index}
            index={memberList.length < 4 ? index : index + 1}
            size="36px"
          >
            <AssigneeTooltip
              toolTipText={`${filteredPerson.first_name}${
                filteredPerson.last_name ? ` ${filteredPerson.last_name}` : ``
              }`}
            />
            <RoundPersonImage
              src={filteredPerson.avatar ? filteredPerson.avatar : Avatar5}
              alt="addPeople"
              size="36px"
            />
          </AssigneeRoundImage>
        ))}
      {moreAssignee && (
        <ShowMoreAssigneeList ref={moreRef1}>
          {memberList.map((member, index) => (
            <Fragment key={index}>
              <AssigneeRoundImage index={0} size="30px">
                <RoundPersonImage
                  src={member.avatar ? member.avatar : Avatar5}
                  alt=""
                  size="30px"
                  borderColor="#c2cce1"
                />
              </AssigneeRoundImage>
              <AssignListText>{`${member.first_name}${
                member.last_name ? ` ${member.last_name}` : ``
              }`}</AssignListText>
            </Fragment>
          ))}
        </ShowMoreAssigneeList>
      )}
    </AssigneeRoundImageSection>
  );
};
