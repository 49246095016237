export const getErrorMessage = (errorMessage) => {
  switch (errorMessage) {
    case "Account with this email/username does not exists":
      return "Couldn't find your account!";
    case "Unable to log in with provided credentials.":
      return "Wrong password!";
    case "provided email address is not verified":
      return "Provided email address is not verified!";
    default:
      return "An unexpected error occurred. Please try again.";
  }
};
