import { useState, useEffect, Fragment } from "react";
import {
  CommonText,
  TableText,
  ColoredText,
  CommonGrid,
  CenteredTooltip,
} from "styledComponents/common";
import LinearActivityBar from "../../../components/ProgressBar/LinearActivityBar";
import { Line } from "react-chartjs-2";
import { formatDurationToHoursMinutes } from "../../../utils/helper";
import {
  TableWeeklyContainer,
  TimeActivityWeeklyTableRow,
  ColoredLine,
  TableItem,
  TableTitleItem,
  GraphContainer,
  NotesText,
} from "../../../styledComponents/reports";
import RoundImageNameComp from "../../../components/RoundImageName/RoundImageNameComp";

import { reportChartOptions } from "../../../utils/chartOptions";
import {
  IdleTimeDurationBox,
  IdleTimeDurationDiv,
} from "custom_modules/TimesheetApproval/AwaitingApprovals/awaitingApprovalStyles";

const TimeActivityWeeklyTable = ({
  report,
  showGraph,
  index,
  plan,
  getNotes,
  selectedOrganization,
  setIsNotesModalOpen,
  setNoteMember,
}) => {
  const [chartState, setChartState] = useState({
    labels: [],
    datasets: [
      {
        label: "Average Activity",
        fill: "start",
        lineTension: 0.1,
        backgroundColor: "rgba(32, 190, 173, 0.4)",
        borderColor: "#20bead",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "#20bead",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 2,
        pointHoverRadius: 7,
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "#20bead",
        pointHoverBorderWidth: 2,
        pointRadius: 6,
        pointHitRadius: 7,
        data: [],
      },
    ],
  });

  useEffect(() => {
    let labels = [];
    let datas = [];
    let borderColors = [];
    let state = { ...chartState };
    if (report && report.data && report.data.length > 0) {
      report.data.map((info) => {
        labels.push(info.member_name);
        datas.push(parseInt(info.member_average_activity));
        if (info.member_average_activity >= 60) {
          borderColors.push("#20bead");
        } else if (info.member_average_activity >= 30) {
          borderColors.push("#F2C741");
        } else {
          borderColors.push("#FE5151");
        }
      });
    }

    async function sequence() {
      try {
        state.labels = [...labels];
        state.datasets[0].data = [...datas];
        state.datasets[0].pointBorderColor = [...borderColors];
        state.datasets[0].pointHoverBorderColor = [...borderColors];

        setChartState(state);
      } catch (error) {
        console.log(error);
      }
    }
    sequence();

    return () => {
      labels = null;
      datas = null;
      state = null;
    };
  }, [report]);

  const getData = (canvas) => {
    const chartConfig = { ...chartState };
    const ctx = canvas.getContext("2d");
    const gradient = ctx.createLinearGradient(0, 0, 0, 400);
    gradient.addColorStop(0, "rgba(32, 190, 173, 1)");
    gradient.addColorStop(0.33, "rgba(32, 190, 173, 0.5)");
    gradient.addColorStop(0.66, "rgba(32, 190, 173, 0.15)");
    gradient.addColorStop(1, "rgba(32, 190, 173, 0)");

    chartConfig.datasets[0].backgroundColor = gradient;

    return {
      ...chartConfig,
    };
  };

  const handleGetNotes = (date, memberId, memberName) => {
    if (selectedOrganization && selectedOrganization.id && date && memberId) {
      const payload = {
        organization_id: selectedOrganization.id,
        date,
        user_id: memberId,
      };
      getNotes(payload);
      setNoteMember({
        id: memberId,
        name: memberName,
      });
      setTimeout(() => {
        setIsNotesModalOpen(true);
      }, 400);
    }
  };

  return (
    <div style={{ marginTop: `20px` }}>
      <TableWeeklyContainer index={index}>
        {showGraph ? (
          <GraphContainer>
            <Line
              id="reportChart"
              data={getData}
              options={reportChartOptions()}
            />
          </GraphContainer>
        ) : (
          <Fragment>
            <TimeActivityWeeklyTableRow title plan={plan}>
              <TableTitleItem>
                <TableText>Member</TableText>
              </TableTitleItem>
              <TableTitleItem>
                <TableText>Time Worked</TableText>
              </TableTitleItem>
              {plan && plan === "monitored" && (
                <>
                  <TableTitleItem>
                    <TableText>Idle Time</TableText>
                  </TableTitleItem>
                  <TableTitleItem>
                    <TableText>Average Activity</TableText>
                  </TableTitleItem>
                  <TableTitleItem>
                    <TableText>Active Time</TableText>
                  </TableTitleItem>
                  <TableTitleItem>
                    <TableText>Neutral Time</TableText>
                  </TableTitleItem>
                </>
              )}
              {/* <TableTitleItem>
                <TableText>Notes</TableText>
              </TableTitleItem> */}
            </TimeActivityWeeklyTableRow>
            <ColoredLine />
            {report &&
              report &&
              report.length > 0 &&
              report.map((info, index) => {
                return (
                  <TimeActivityWeeklyTableRow key={index} plan={plan}>
                    <TableItem>
                      <RoundImageNameComp
                        imgSource={info.avatar}
                        name={info.member_name}
                        index={index}
                        imageSize="32px"
                        color={info.member_color}
                      />
                    </TableItem>
                    <TableItem>
                      <CommonText>
                        {formatDurationToHoursMinutes(
                          info.time_worked_in_seconds,
                          false
                        )}
                      </CommonText>
                    </TableItem>
                    {plan && plan === "monitored" && (
                      <>
                        <TableItem>
                          {info.idle_time && info.idle_time > 0 ? (
                            <IdleTimeDurationDiv>
                              <IdleTimeDurationBox>
                                {formatDurationToHoursMinutes(
                                  info.idle_time,
                                  true
                                )}
                              </IdleTimeDurationBox>
                              <CenteredTooltip
                                right="calc(100% + 4px)"
                                top="0"
                                left="unset"
                              >
                                Idle Time
                              </CenteredTooltip>
                            </IdleTimeDurationDiv>
                          ) : (
                            "-"
                          )}
                        </TableItem>
                        <TableItem>
                          <CommonGrid
                            justifyContent="start"
                            columns="minmax(auto , 170px) auto"
                          >
                            <LinearActivityBar
                              percent={parseInt(info.average_weekly_activity)}
                            />
                            <CommonText>
                              {`${parseInt(info.average_weekly_activity)}%`}
                            </CommonText>
                          </CommonGrid>
                        </TableItem>
                        <TableItem>
                          <ColoredText color="#20BEAD" fontSize="14px">
                            {formatDurationToHoursMinutes(
                              info.active_time,
                              true
                            )}
                          </ColoredText>
                        </TableItem>
                        <TableItem>
                          <CommonText>
                            {formatDurationToHoursMinutes(info.neutral_time)}
                          </CommonText>
                        </TableItem>
                      </>
                    )}
                    {/* <TableItem>
                      <NotesText
                        onClick={() =>
                          handleGetNotes(
                            moment(report.date).format("YYYY-MM-DD"),
                            info.user_id,
                            info.member_name
                          )
                        }
                      >
                        Notes
                      </NotesText>
                    </TableItem> */}
                  </TimeActivityWeeklyTableRow>
                );
              })}
          </Fragment>
        )}
      </TableWeeklyContainer>
    </div>
  );
};

export default TimeActivityWeeklyTable;
