import {
  PopupContainer,
  PopupBackgroundOverlay,
  PopupWrapper,
  PopupContent,
  PopupItemsContainer,
  OrganizationItemsContainer,
  OrgSectionContainer,
  OrgScrollSection,
  OrgButtonContainer,
  OrgIconDiv,
  OrgText,
  CreateOrgButton,
  ProfileItemsContainer,
  VerticalDivider,
  ProfileMenuItem,
  BottomSection,
  DividerLine,
  DownloadPlatformIcon,
  DownloadLink,
} from "./popupStyles";
import {
  CommonFlex,
  CommonGrid,
  CommonText,
  ColoredText,
  FirstWordRound,
  CommonImage,
} from "../../styledComponents/common";
import { SecondaryButton } from "../../styledComponents/buttons";
import { ProfilePicture } from "./sidebarStyles";

import macIcon from "../../assets/img/platformIcons/gray/mac-icon.svg";
import windowsIcon from "../../assets/img/platformIcons/gray/windows-icon.svg";
import linuxIcon from "../../assets/img/platformIcons/gray/linux.svg";
import playstoreIcon from "../../assets/img/platformIcons/gray/playstore.svg";

import plusBlack from "../../assets/img/icons/plus_black.svg";
import archivedIcon from "../../assets/img/icons/archived.svg";
import FirstRoundLetterComp from "../../components/FirstRoundLetter/FirstRoundLetter";

const colorList = ["#708CEB", "#76C794", "#73C0FC", "#F9828B"];

const ProfileSection = (props) => {
  return (
    <ProfileItemsContainer sidebarMini={props.sidebarMini}>
      <CommonGrid
        columns="36px 232px"
        gap="12px"
        padding="0 15px 10px"
        justifyContent="flex-start"
      >
        {props.profilePicture ? (
          <ProfilePicture src={props.profilePicture} alt="" $size="36px" />
        ) : (
          <FirstRoundLetterComp
            size="36px"
            text={props.userFullName}
            color={props.userProfileColor}
          />
        )}

        <CommonGrid columns="100%" justifyContent="start" gap="0">
          <CommonText style={{ wordWrap: "break-word", hyphens: "auto" }}>
            {props.userFullName}
          </CommonText>
          <CommonText
            style={{ wordWrap: "break-word", hyphens: "none" }}
            $label
            fontWeight="500"
            fontSize="12px"
          >
            {props.userEmail}
          </CommonText>
        </CommonGrid>
      </CommonGrid>
      {props.userTimezone && (
        <CommonFlex gap="5px" padding="0px 15px 10px">
          <CommonText $label fontWeight="500" fontSize="13px">
            Timezone:
          </CommonText>
          <CommonText fontSize="13px">{props.userTimezone}</CommonText>
        </CommonFlex>
      )}
      {props.selectedOrganization && props.selectedOrganization.plan && (
        <CommonFlex gap="5px" padding="0px 15px 10px">
          <CommonText $label fontWeight="500" fontSize="13px">
            Plan:
          </CommonText>
          <ColoredText fontSize="13px" type="blue" semibold>
            {props.selectedOrganization.plan.name}
          </ColoredText>
        </CommonFlex>
      )}

      <DividerLine />
      <ProfileMenuItem
        onClick={() => {
          props.history.push("/user/user-profile");
          props.closePopup();
        }}
        margin="5px 10px 0"
      >
        Profile Settings
      </ProfileMenuItem>
      {/* <ProfileMenuItem
        onClick={() => {
          props.closePopup();
        }}
        margin="5px 10px 0"
      >
        Notifications
      </ProfileMenuItem> */}
      <ProfileMenuItem
        onClick={() => {
          props.closePopup();
          window.open("https://support.apploye.com", "_blank");
        }}
        margin="5px 10px 0"
      >
        Help
      </ProfileMenuItem>
      <ProfileMenuItem
        onClick={() => {
          props.closePopup();
          window.open("mailto:support@apploye.com");
        }}
        margin="5px 10px 0"
      >
        Contact Support
      </ProfileMenuItem>
      <ProfileMenuItem
        onClick={() => {
          props.closePopup();
          props.clearNavOpen();
          props.handleLogout();
        }}
        margin="0px 10px 10px"
      >
        Log Out
      </ProfileMenuItem>

      <BottomSection>
        <DividerLine />
        <CommonFlex
          justifyContent="space-between"
          padding="0 15px"
          style={{ height: "55px" }}
        >
          <CommonText $label fontWeight="500" margin="0">
            Download Apps
          </CommonText>
          <CommonGrid columns="auto auto auto auto" gap="7px">
            <DownloadLink href="https://apploye.com/download" target="_blank">
              <DownloadPlatformIcon src={macIcon} alt="" />
            </DownloadLink>
            <DownloadLink href="https://apploye.com/download" target="_blank">
              <DownloadPlatformIcon src={windowsIcon} alt="" />
            </DownloadLink>

            <DownloadLink href="https://apploye.com/download" target="_blank">
              <DownloadPlatformIcon src={linuxIcon} alt="" />
            </DownloadLink>
            <DownloadLink href="https://apploye.com/download" target="_blank">
              <DownloadPlatformIcon src={playstoreIcon} alt="" />
            </DownloadLink>
          </CommonGrid>
        </CommonFlex>
      </BottomSection>
    </ProfileItemsContainer>
  );
};

const SingleOrgItem = (props) => {
  const { selectOrg, organization, selectedOrgId, closePopup, index } = props;
  return (
    <CommonFlex
      padding="0 10px 5px"
      gap="12px"
      onClick={() => {
        selectOrg(organization);
        // closePopup();
      }}
    >
      <OrgIconDiv
        selected={
          selectedOrgId && organization.id && organization.id === selectedOrgId
        }
      >
        <FirstWordRound
          size="26px"
          backColor={
            organization.is_active
              ? organization.color
                ? organization.color
                : colorList[index % 4]
              : "#9eafc2"
          }
        >
          {organization.name.charAt(0).toUpperCase()}
        </FirstWordRound>
      </OrgIconDiv>
      <OrgText
        selected={
          selectedOrgId && organization.id && organization.id === selectedOrgId
        }
      >
        {organization.name.length < 25
          ? organization.name
          : `${organization.name.slice(0, 25)}...`}
      </OrgText>
    </CommonFlex>
  );
};

const OrganizationSection = (props) => {
  const {
    history,
    closePopup,
    selectedOrganization,
    selectOrg,
    selectedOrgId,
    activeOrgsList,
    archivedOrgsList,
  } = props;
  return (
    <OrganizationItemsContainer>
      <OrgSectionContainer>
        {activeOrgsList && activeOrgsList.length > 0 && (
          <OrgScrollSection>
            {activeOrgsList.map((org, index) => (
              <SingleOrgItem
                key={index}
                index={index}
                closePopup={closePopup}
                selectedOrgId={selectedOrgId}
                organization={org}
                selectOrg={selectOrg}
              />
            ))}
          </OrgScrollSection>
        )}

        <CommonFlex
          padding="0 10px 5px"
          gap="12px"
          onClick={() => {
            history.push("/user/add-organization");
            closePopup();
          }}
        >
          <OrgIconDiv>
            <CreateOrgButton size="26px">
              <CommonImage src={plusBlack} alt="" size="11px" />
            </CreateOrgButton>
          </OrgIconDiv>
          <OrgText>Create New Organization</OrgText>
        </CommonFlex>
      </OrgSectionContainer>
      {archivedOrgsList && archivedOrgsList.length > 0 && (
        <>
          <DividerLine />
          <OrgSectionContainer>
            <CommonFlex padding="10px 18px" gap="12px">
              <CommonImage src={archivedIcon} alt="" />
              <CommonText $label fontWeight="500" fontSize="12.5px">
                Archived ({archivedOrgsList.length})
              </CommonText>
            </CommonFlex>
            <OrgScrollSection
              height={
                !activeOrgsList ||
                (activeOrgsList && activeOrgsList.length === 0)
                  ? "236px"
                  : activeOrgsList &&
                    activeOrgsList.length &&
                    activeOrgsList.length < 3
                  ? `${236 - activeOrgsList.length * 39}px`
                  : "119px"
              }
            >
              {archivedOrgsList.map((org, index) => (
                <SingleOrgItem
                  key={index}
                  closePopup={closePopup}
                  selectedOrgId={selectedOrgId}
                  organization={org}
                  selectOrg={selectOrg}
                />
              ))}
            </OrgScrollSection>
          </OrgSectionContainer>
        </>
      )}
      <BottomSection>
        <DividerLine />
        <OrgButtonContainer>
          <SecondaryButton
            height="32px"
            fontSize="12px"
            onClick={() => {
              history.push("/user/organizations");
              closePopup();
            }}
          >
            Organization Details
          </SecondaryButton>
        </OrgButtonContainer>
      </BottomSection>
    </OrganizationItemsContainer>
  );
};

const OrgAndProfilePopup = (props) => {
  const {
    closePopup,
    popupOpen,
    sidebarMini,
    history,
    handleLogout,
    clearNavOpen,
    profilePicture,
    userFullName,
    userProfileColor,
    userEmail,
    userTimezone,
    selectedOrganization,
    selectOrg,
    selectedOrgId,
    activeOrgsList,
    archivedOrgsList,
  } = props;
  return (
    <PopupContainer>
      <PopupBackgroundOverlay
        onClick={() => {
          closePopup();
        }}
        popupOpen={popupOpen}
      />
      <PopupWrapper popupOpen={popupOpen}>
        <PopupContent>
          <PopupItemsContainer sidebarMini={sidebarMini}>
            <OrganizationSection
              history={history}
              closePopup={closePopup}
              selectedOrganization={selectedOrganization}
              selectedOrgId={selectedOrgId}
              selectOrg={selectOrg}
              activeOrgsList={activeOrgsList}
              archivedOrgsList={archivedOrgsList}
            />
            <VerticalDivider />
            <ProfileSection
              history={history}
              closePopup={closePopup}
              handleLogout={handleLogout}
              clearNavOpen={clearNavOpen}
              profilePicture={profilePicture}
              userFullName={userFullName}
              userProfileColor={userProfileColor}
              userEmail={userEmail}
              userTimezone={userTimezone}
              selectedOrganization={selectedOrganization}
            />
          </PopupItemsContainer>
        </PopupContent>
      </PopupWrapper>
    </PopupContainer>
  );
};

export default OrgAndProfilePopup;
