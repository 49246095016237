import { useState, useEffect } from "react";

import {
  PageTitle,
  HeaderContainer,
  InputWithLabelSection,
  FilterLabel,
  InputField,
} from "../../styledComponents/common";
import {
  ColoredButtonWithIcon,
  ButtonIcon,
} from "../../styledComponents/buttons";

import TeamsTable from "./TeamsTableNew";

import TeamDeleteModal from "./TeamDeleteModal";

import SearchWithButton from "../../components/Search/SearchWithButton";

import TopPagination from "../../components/Pagination/TopPagination/TopPagination";
import BottomPagination from "../../components/Pagination/BottomPagination/BottomPagination";

import Plus from "../../assets/img/icons/plus_white.svg";

const Teams = ({
  selectedOrganization,
  getTeamList,
  teamsList,
  isLoading,
  history,
  deleteTeamLoading,
  deleteTeam,

  // pagination
  totalTeamsCount,
  teamPageSize,

  reloadTeams,
}) => {
  const [teamDeleteId, setTeamDeleteId] = useState("");
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");
  const [currentSearchedTerm, setCurrentSearchedTerm] = useState("");

  // pagination
  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  const fetchTeamList = () => {
    if (selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization.id,
        name: currentSearchedTerm
          ? currentSearchedTerm.toLowerCase().trim()
          : undefined,
        page: currentPageNumber,
      };
      getTeamList(payload);
    }
  };

  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id) {
      fetchTeamList();
    }
  }, [
    currentPageNumber,
    currentSearchedTerm,
    selectedOrganization,
    reloadTeams,
  ]);
  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id) {
      setCurrentPageNumber(1);
    }
  }, [selectedOrganization]);

  const handleSearchTeamByName = () => {
    if (selectedOrganization && selectedOrganization.id) {
      if (searchTerm && searchTerm.trim()) {
        setCurrentSearchedTerm(searchTerm);
      } else {
        setCurrentSearchedTerm("");
      }
    }
  };

  const handleClearSearchTeamByName = () => {
    if (
      currentSearchedTerm &&
      selectedOrganization &&
      selectedOrganization.id
    ) {
      setCurrentSearchedTerm("");
    }
    setTimeout(() => {
      setSearchTerm("");
    }, 100);
  };

  const handlePageChange = (page) => {
    setCurrentPageNumber(page);
  };

  const handleDeleteTeam = () => {
    if (selectedOrganization && selectedOrganization.id && teamDeleteId) {
      let payload = {
        organization_id: selectedOrganization.id,
        team_id: teamDeleteId,
        currentPageNumber,
      };
      deleteTeam(payload);
      setTimeout(() => {
        setIsDeleteModalVisible(!isDeleteModalVisible);
      }, 600);
    }
  };

  const toggleDeleteModal = (teamId) => {
    if (!isDeleteModalVisible) {
      setTeamDeleteId(teamId);
    }
    setIsDeleteModalVisible(!isDeleteModalVisible);
  };

  const onSearchTermChange = (e) => {
    const { value } = e.target;
    setSearchTerm(value);
  };

  return (
    <div className="content">
      <TeamDeleteModal
        isOpen={isDeleteModalVisible}
        toggle={() => setIsDeleteModalVisible(!isDeleteModalVisible)}
        handleDelete={handleDeleteTeam}
        isLoading={deleteTeamLoading}
      />
      <PageTitle>Teams</PageTitle>
      <HeaderContainer columns="260px auto">
        <SearchWithButton
          itemName="Team"
          searchTermName="team name"
          searchInput={searchTerm}
          onInputChange={onSearchTermChange}
          handleSearch={handleSearchTeamByName}
          handleClearSearch={handleClearSearchTeamByName}
        />

        {selectedOrganization &&
          (selectedOrganization.role === "owner" ||
            selectedOrganization.role === "admin") && (
            <ColoredButtonWithIcon
              onClick={() => history.push("/user/add-team")}
            >
              <ButtonIcon src={Plus} alt="" />
              Add Team
            </ColoredButtonWithIcon>
          )}
      </HeaderContainer>
      {totalTeamsCount && totalTeamsCount > 0 && teamPageSize ? (
        <TopPagination
          itemName="teams"
          totalCount={totalTeamsCount}
          currentPage={currentPageNumber}
          onPageChange={(page) => handlePageChange(page)}
          pageSize={teamPageSize}
        />
      ) : null}
      <TeamsTable
        selectedOrganization={selectedOrganization}
        searchTerm={searchTerm}
        teamList={teamsList}
        history={history}
        toggleDeleteModal={toggleDeleteModal}
        isLoading={isLoading}
      />
      {totalTeamsCount && totalTeamsCount > 0 && teamPageSize ? (
        <BottomPagination
          totalCount={totalTeamsCount}
          currentPage={currentPageNumber}
          onPageChange={(page) => handlePageChange(page)}
          pageSize={teamPageSize}
        />
      ) : null}
    </div>
  );
};

export default Teams;
