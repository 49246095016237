import { useState, useEffect, useRef, forwardRef } from "react";

import {
  OnboardingStepsContainer,
  OnboardingArtworkDiv,
  OnboardingArtwork,
  AddOrganizationContainer,
  OnboardingForm,
  OnboardingInputContainer,
  OnboardingInputLabel,
  OnboardingInput,
  OnboardingFormAlert,
  InputRequiredStar,
} from "../OnboardingSteps/onboardingStepsStyles";
import { CommonText, CommonFlex } from "../../../styledComponents/common";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../../styledComponents/buttons";
import {
  LearnMoreDiv,
  HelpIcon,
  LearnMoreLink,
  JoinOrganizationButton,
  LogOutButton,
} from "./requestJoinOrgStyles";

import PreviousButton from "../OnboardingSteps/StepComponents/PreviousButton";
import ButtonLoader from "../../../components/DropdownLoader/DropdownLoader";

import artwork from "../../../assets/img/onboardingImg/background-artwork.png";
import helpIcon from "../../../assets/img/icons/help.svg";

const JoinOrgSection = forwardRef((props, ref) => {
  const [orgCode, setOrgCode] = useState("");
  const [saveErrors, setSaveErrors] = useState({});

  const onTextChange = (e) => {
    setSaveErrors({});
    setOrgCode(e.target.value);
  };

  useEffect(() => {
    if (props.errorMessage) {
      setSaveErrors({ code: props.errorMessage });
    }
  }, [props.errorMessage]);

  const checkError = () => {
    let saveErrors = {};
    let formIsValid = true;
    if (!orgCode) {
      saveErrors["code"] = "Please enter a valid code.";
      formIsValid = false;
    } else if (orgCode.length !== 6) {
      saveErrors["code"] = "Code must contain 6 characters.";
      formIsValid = false;
    }
    setSaveErrors(saveErrors);
    return formIsValid;
  };

  const handleSubmitRequest = (e) => {
    e.preventDefault();
    if (checkError()) {
      const payload = {
        code: orgCode,
      };
      props.requestToJoin(payload);
    }
  };

  return (
    <div ref={ref}>
      <CommonText name title fontSize="32px" tabFontSize="26px">
        Join An Existing Organization
      </CommonText>
      <LearnMoreDiv>
        <HelpIcon src={helpIcon} alt="" />
        <CommonText
          $label
          fontSize="16px"
          tabFontSize="14px"
          fontWeight="500"
          margin="0 0 0 15px"
        >
          Ask your organization owner for organization code.
        </CommonText>
        <LearnMoreLink
          target="_blank"
          rel="noopener noreferrer"
          href="https://apploye.com/help/how-to-join-an-existing-organization/"
        >
          Learn More...
        </LearnMoreLink>
      </LearnMoreDiv>
      <AddOrganizationContainer style={{ marginTop: "50px" }}>
        <PreviousButton handleClick={props.handleBack} />
        <OnboardingForm onSubmit={(e) => handleSubmitRequest(e)}>
          <OnboardingInputContainer>
            <OnboardingInputLabel>
              Organization Code <InputRequiredStar>*</InputRequiredStar>
            </OnboardingInputLabel>
            <OnboardingInput
              type="text"
              id="orgCode"
              value={orgCode}
              onChange={(e) => onTextChange(e)}
              placeholder="Enter the code here..."
            />
            {saveErrors &&
              Object.keys(saveErrors).length > 0 &&
              saveErrors.code && (
                <OnboardingFormAlert>{saveErrors.code}</OnboardingFormAlert>
              )}
          </OnboardingInputContainer>
          <PrimaryButton
            type="submit"
            height="50px"
            fontSize="16px"
            style={{ marginTop: "50px" }}
            onClick={(e) => handleSubmitRequest(e)}
          >
            {props.isLoading ? (
              <ButtonLoader color="#fff" loading={props.isLoading} />
            ) : (
              `Join Organization`
            )}
          </PrimaryButton>
        </OnboardingForm>
      </AddOrganizationContainer>
    </div>
  );
});

const RequestSentNotificationSection = forwardRef((props, ref) => {
  return (
    <div ref={ref}>
      <CommonText name title fontSize="32px">
        Request Sent!
      </CommonText>
      <CommonText margin="30px 0 0" fontSize="22px" style={{ width: "60%" }}>
        We have sent an email to your organization owner to let you join the
        organization. We will redirect you to dashboard once your owner accepts
        your request.
      </CommonText>
      <CommonText margin="40px 0 0" fontSize="22px" style={{ width: "60%" }}>
        Meanwhile, you can download our desktop app and get ready to track time
        Right away.
      </CommonText>
      <JoinOrganizationButton
        href="https://apploye.com/download"
        target="_blank"
        rel="noopener noreferrer"
      >
        Download App
      </JoinOrganizationButton>
    </div>
  );
});

const RequestDeclinedNotificationSection = forwardRef((props, ref) => {
  const handleResendCode = () => {
    if (props.requestedOrgCode) {
      const payload = {
        code: props.requestedOrgCode,
      };
      props.requestToJoin(payload);
    }
  };
  return (
    <div ref={ref}>
      <CommonText name title fontSize="32px">
        Request Declined!
      </CommonText>
      <CommonText margin="30px 0 0" fontSize="22px" style={{ width: "60%" }}>
        Your organization owner has declined your request to join "
        {props.orgName}"
      </CommonText>
      <CommonFlex margin="60px 0 0" gap="15px">
        <PrimaryButton
          height="50px"
          fontSize="16px"
          padding={props.isLoading ? "0 75px" : "0 35px"}
          onClick={() => handleResendCode()}
        >
          {props.isLoading ? (
            <ButtonLoader color="#fff" loading={props.isLoading} />
          ) : (
            `Resend Request`
          )}
        </PrimaryButton>
        <SecondaryButton
          height="50px"
          fontSize="16px"
          fontWeight="600"
          textColor="#20bead"
          onClick={() => props.history.push("/setup/onboarding")}
        >
          Create Organization
        </SecondaryButton>
      </CommonFlex>
      {/* <LogOutButton>Sign me out</LogOutButton> */}
    </div>
  );
});

const RequestJoinOrg = (props) => {
  const [requestStatus, setRequestStatus] = useState("none");
  const [requestedOrgName, setRequestedOrgName] = useState("");
  const [requestedOrgCode, setRequestedOrgCode] = useState("");

  // refs
  const joinOrgRef = useRef(null);
  const requestSentRef = useRef(null);
  const requestDeclinedRef = useRef(null);

  useEffect(() => {
    if (props.joinRequest) {
      // if (
      //   props.joinRequest.length > 0 &&
      //   props.joinRequest[props.joinRequest.length - 1].status
      // ) {
      //   const request = props.joinRequest[props.joinRequest.length - 1];
      //   console.log(request);
      //   setRequestStatus(request.status);
      //   setRequestedOrgName(request.organization.name);
      //   setRequestedOrgCode(request.code);
      // }
      setTimeout(() => {
        props.clearJoinRequests();
      }, 1500);
    } else {
      props.getJoinRequests();
    }
  }, []);

  useEffect(() => {
    if (props.joinRequest && props.joinRequest.length > 0) {
      const request = props.joinRequest[props.joinRequest.length - 1];
      setRequestStatus(request.status);
      setRequestedOrgName(request.organization.name);
      setRequestedOrgCode(request.code);
      if (request.status === "accepted") {
        props.history.push({
          pathname: "/user/dashboard",
          state: { message: "user-onboard" },
        });
      }
    }
  }, [props.joinRequest]);

  const handleBack = () => {
    props.history.push("/setup/add-or-join");
  };

  return (
    <div>
      <OnboardingStepsContainer>
        {requestStatus === "none" && (
          <JoinOrgSection
            ref={joinOrgRef}
            handleBack={handleBack}
            isLoading={props.isLoading}
            requestToJoin={props.requestToJoinOrganization}
            errorMessage={props.errorMessage}
          />
        )}
        {requestStatus === "pending" && (
          <RequestSentNotificationSection ref={requestSentRef} />
        )}
        {requestStatus === "declined" && (
          <RequestDeclinedNotificationSection
            ref={requestDeclinedRef}
            orgName={requestedOrgName}
            orgCode={requestedOrgCode}
            history={props.history}
            isLoading={props.isLoading}
            requestToJoin={props.requestToJoinOrganization}
          />
        )}
      </OnboardingStepsContainer>
      <OnboardingArtworkDiv>
        <OnboardingArtwork src={artwork} alt="" />
      </OnboardingArtworkDiv>
    </div>
  );
};

export default RequestJoinOrg;
