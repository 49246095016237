import { useState, useEffect, useRef } from "react";
import {
  OnboardingStepsContainer,
  OnboardingArtworkDiv,
  OnboardingArtwork,
  PricingPlanContainer,
} from "./onboardingStepsStyles";
import TagManager from "react-gtm-module";
import AddOrganization from "./StepComponents/AddOrganization";
import AddProject from "./StepComponents/AddProject";
import PricingPlan from "./StepComponents/PricingPlan/PricingPlan";
import InviteMembers from "./StepComponents/InviteMembers";
import PreviousButton from "./StepComponents/PreviousButton";

import artwork from "../../../assets/img/onboardingImg/background-artwork.png";

const OnboardingSteps = (props) => {
  const [organizationData, setOrganizationData] = useState(null);
  const [projectsData, setProjectsData] = useState([]);
  const [planData, setPlanData] = useState(null);

  const [activeStep, setActiveStep] = useState("organization");
  const [hideArtwork, setHideArtwork] = useState(false);
  const [showInviteSection, setShowInviteSection] = useState(false);

  const tagManagerArgs = {
    gtmId: "GTM-NH9X5RJV",
  };

  // refs
  const organizationRef = useRef(null);
  const pricingRef = useRef(null);
  const projectRef = useRef(null);
  const inviteMembersRef = useRef(null);

  const { location, advertise } = props;

  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
    window.addEventListener("scroll", onScroll);
    props.getOrganizationPlans();
    if (!props.showOnboarding) {
      // props.history.push("/user/dashboard");
    }
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  useEffect(() => {
    if (props.redirect) {
      props.history.push({
        pathname: "/setup/onboarding-loader",
        state: {
          message: "onboard",
          advertise: location.state && location.state.advertise,
        },
      });
    }
  }, [props.redirect]);

  useEffect(() => {
    if (planData && planData.id) {
      if (planData.name !== "Solo") {
        setShowInviteSection(true);
      } else if (planData.name === "Solo") {
        setShowInviteSection(false);
        handleSubmitDataFromPlan(planData.id);
      }
      let orgData = { ...organizationData };
      orgData.plan_id = planData.id;
      setOrganizationData(orgData);
    }
  }, [planData]);

  useEffect(() => {
    if (
      location.state &&
      location.state.message &&
      location.state.message === "onboard" &&
      location.state.advertise &&
      location.state.advertise === "google"
    ) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "onboardingSteps",
        page: location.pathname + location.search, // Send the current URL as part of the event
      });
    }
  }, [location]);

  const onScroll = () => {
    if (pricingRef && pricingRef.current) {
      if (
        window.scrollY > pricingRef.current.offsetTop - 600 &&
        window.scrollY <
          pricingRef.current.offsetTop + pricingRef.current.clientHeight &&
        hideArtwork === false
      ) {
        setHideArtwork(true);
      } else {
        setHideArtwork(false);
      }
    }
  };

  const handleBack = (step) => {
    let offsetPosition;
    if (step === "organization") {
      props.history.push("/setup/add-or-join");
    } else if (step === "project") {
      if (organizationRef && organizationRef.current) {
        offsetPosition = organizationRef.current.offsetTop - 70;
      }
      setActiveStep("organization");
    } else if (step === "pricing") {
      if (projectRef && projectRef.current) {
        offsetPosition = projectRef.current.offsetTop - 100;
      }
      setActiveStep("project");
    } else if (step === "invite-member") {
      if (pricingRef && pricingRef.current) {
        offsetPosition = pricingRef.current.offsetTop - 50;
      }
      setActiveStep("pricing");
    }
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  const handleNext = (step) => {
    let offsetPosition;
    if (step === "organization") {
      if (projectRef && projectRef.current) {
        offsetPosition = projectRef.current.offsetTop - 100;
        setActiveStep("project");
      }
    } else if (step === "project") {
      if (pricingRef && pricingRef.current) {
        offsetPosition = pricingRef.current.offsetTop - 50;
        setActiveStep("pricing");
      }
    } else if (step === "pricing") {
      setTimeout(() => {
        if (inviteMembersRef && inviteMembersRef.current) {
          offsetPosition = inviteMembersRef.current.offsetTop - 100;
          setActiveStep("invite-member");
          window.scrollTo({
            top: offsetPosition,
            behavior: "smooth",
          });
        }
      }, 400);
    }
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  const handleSubmitDataFromPlan = (planId) => {
    if (organizationData && organizationData.name && planId) {
      const organization = { ...organizationData };
      organization.plan_id = planId;
      const payload = {
        organization,
        projects: projectsData,
        invitations: null,
      };
      props.saveOnboardingData(payload);
    }
  };

  const handleSubmitDataFromInviteMembers = (membersData) => {
    if (organizationData && organizationData.name && organizationData.plan_id) {
      const payload = {
        organization: organizationData,
        projects: projectsData,
        invitations: membersData && membersData.length > 0 ? membersData : null,
      };
      props.saveOnboardingData(payload);
    }
  };
  return (
    <div>
      <OnboardingStepsContainer>
        <AddOrganization
          ref={organizationRef}
          activeStep={activeStep}
          handleBack={handleBack}
          handleNext={handleNext}
          organizationData={organizationData}
          projectsData={projectsData}
          setOrganizationData={setOrganizationData}
        />
        <AddProject
          ref={projectRef}
          activeStep={activeStep}
          handleBack={handleBack}
          handleNext={handleNext}
          setProjectsData={setProjectsData}
          organization={organizationData}
        />
        <PricingPlanContainer ref={pricingRef}>
          <PreviousButton handleClick={handleBack} type="pricing" />
          <PricingPlan
            activeStep={activeStep}
            plans={props.subscriptionPlans}
            selectedPlan={planData}
            setSelectedPlan={setPlanData}
            organization={organizationData}
            handleNext={handleNext}
            handleBack={handleBack}
          />
        </PricingPlanContainer>
        {showInviteSection && (
          <InviteMembers
            ref={inviteMembersRef}
            handleBack={handleBack}
            handleNext={handleNext}
            organization={organizationData}
            projects={projectsData}
            isLoading={props.isLoading}
            handleSubmitData={handleSubmitDataFromInviteMembers}
          />
        )}
      </OnboardingStepsContainer>
      <OnboardingArtworkDiv hide={hideArtwork}>
        <OnboardingArtwork hide={hideArtwork} src={artwork} alt="" />
      </OnboardingArtworkDiv>
    </div>
  );
};

export default OnboardingSteps;
