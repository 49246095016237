import React, { useEffect, useState, useMemo } from "react";
import LinearActivityBar from "../../../components/ProgressBar/LinearActivityBar";
import moment from "moment";
import { formatDurationToHoursMinutes } from "../../../utils/helper";
import {
  AssigneesManageImgTextGrid,
  AssigneesManageRoundImage,
} from "../../../styledComponents/createProject";
import {
  UserActivityRow,
  UserActivitySection,
  OuterBox,
  VerticalLine,
} from "../../../styledComponents/Activity";
import {
  FlexSection,
  ActivityTextSection,
} from "../../../styledComponents/members";
import {
  CommonText,
  RoundMiniImage,
  TableText,
  CommonImage,
  CommonFlex,
} from "../../../styledComponents/common";
import alertIcon from "../../../assets/img/icons/alert-grey.svg";

import FirstRoundLetterComp from "../../../components/FirstRoundLetter/FirstRoundLetter";

const CommImageNameComp = ({ imgSource, name, color, backColor }) => (
  <AssigneesManageImgTextGrid>
    {imgSource ? (
      <AssigneesManageRoundImage>
        <RoundMiniImage src={imgSource} alt="" />
      </AssigneesManageRoundImage>
    ) : (
      <FirstRoundLetterComp text={name} color={color} backColor={backColor} />
    )}

    <TableText name>{name}</TableText>
  </AssigneesManageImgTextGrid>
);

const UserActivityInfo = ({
  userActivity,
  selectedOrganization,
  clockInOutList,
}) => {
  const [today, setToday] = useState(moment());
  const [clockIn, setClockIn] = useState(null);
  const [clockOut, setClockOut] = useState(null);

  const [activeTime, setActiveTime] = useState(0);
  const [neutralTime, setNeutralTime] = useState(0);
  const [idleTime, setIdleTime] = useState(0);

  useMemo(() => {
    if (userActivity?.total_duration) {
      const activeDuration =
        userActivity.total_duration * (userActivity.average_activity / 100);
      const idleDuration = userActivity.total_idle_time;
      const neutralDuration = parseInt(
        userActivity.total_duration - activeDuration - idleDuration
      );

      setActiveTime(activeDuration);
      setNeutralTime(neutralDuration);
      setIdleTime(idleDuration);
    }
  }, [userActivity]);

  useEffect(() => {
    if (clockInOutList?.[0]?.attendance?.[0]) {
      const attendance = clockInOutList[0].attendance[0];

      if (attendance.date) {
        setToday(moment(attendance.date));
      }

      if (attendance.clock_in) {
        setClockIn(attendance.clock_in);
      }

      if (attendance.clock_out) {
        setClockOut(attendance.clock_out);
      }
    }
  }, [clockInOutList]);

  const dateFormat = localStorage.getItem("date_format") || "DD-MM-YYYY";
  return userActivity && userActivity.total_duration ? (
    <OuterBox>
      <React.Fragment>
        <UserActivityRow>
          <UserActivitySection align="left">
            {userActivity?.user?.id ? (
              <CommImageNameComp
                name={`${userActivity.user.first_name}${
                  userActivity.user.last_name
                    ? ` ${userActivity.user.last_name}`
                    : ``
                }`}
                imgSource={userActivity.user.avatar || null}
                color={userActivity.user.color}
              />
            ) : null}
            <ActivityTextSection>
              <CommonText fontSize="13.5px" title>
                {today.format(dateFormat)}
              </CommonText>
              <CommonText $label fontSize="12px" fontWeight="500">
                {today.format("dddd")}
              </CommonText>
            </ActivityTextSection>

            <ActivityTextSection>
              <CommonText fontSize="13.5px" title>
                {clockIn ? moment.unix(clockIn).format("h:mm a") : ""} -{" "}
                {clockOut && moment.unix(clockOut).isBefore(moment(), "day")
                  ? moment.unix(clockOut).format("h:mm a")
                  : ""}
              </CommonText>
              <CommonText $label fontSize="12px" fontWeight="500">
                Clock In/Out
              </CommonText>
            </ActivityTextSection>

            <ActivityTextSection>
              <CommonText fontSize="13.5px" title>
                {formatDurationToHoursMinutes(
                  userActivity.total_duration,
                  false
                )}
              </CommonText>
              <CommonText $label fontSize="12px" fontWeight="500">
                Total Time
              </CommonText>
            </ActivityTextSection>
          </UserActivitySection>

          <VerticalLine />
          {selectedOrganization?.configuration?.mouse_track ||
          selectedOrganization?.configuration?.keystroke_track ? (
            <UserActivitySection align="right">
              <ActivityTextSection>
                <FlexSection>
                  <LinearActivityBar
                    percent={parseInt(userActivity.average_activity)}
                  />
                  <CommonText fontSize="13.5px" title>
                    {`${parseInt(userActivity.average_activity)}%`}
                  </CommonText>
                </FlexSection>
                <CommonText $label fontSize="12px" fontWeight="500">
                  Activity
                </CommonText>
              </ActivityTextSection>

              <ActivityTextSection>
                <CommonText fontSize="13.5px" title>
                  {formatDurationToHoursMinutes(activeTime, true)}
                </CommonText>
                <CommonText $label fontSize="12px" fontWeight="500">
                  Active Time
                </CommonText>
              </ActivityTextSection>

              <ActivityTextSection>
                <CommonText fontSize="13.5px" title>
                  {formatDurationToHoursMinutes(neutralTime, false)}
                </CommonText>
                <CommonText $label fontSize="12px" fontWeight="500">
                  Neutral Time
                </CommonText>
              </ActivityTextSection>
              <ActivityTextSection>
                <CommonText
                  fontSize="13.5px"
                  title
                  danger={idleTime ? true : false}
                >
                  {idleTime ? (
                    formatDurationToHoursMinutes(idleTime, true)
                  ) : (
                    <span style={{ display: "flex", justifyContent: "center" }}>
                      -
                    </span>
                  )}
                </CommonText>
                <CommonText $label fontSize="12px" fontWeight="500">
                  Idle Time
                </CommonText>
              </ActivityTextSection>
            </UserActivitySection>
          ) : (
            <UserActivitySection align="right" alert>
              <CommonImage src={alertIcon} alt="" size="20px" />
              <CommonText $label fontSize="12px" fontWeight="500">
                Activity tracking is turned off from activity settings.
              </CommonText>
            </UserActivitySection>
          )}
        </UserActivityRow>
      </React.Fragment>
    </OuterBox>
  ) : null;
};

export default UserActivityInfo;
