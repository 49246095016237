import { useState, useEffect } from "react";
import { TableItem } from "../../../../styledComponents/teams";
import {
  CommonGrid,
  CardTitle,
  TableText,
  CommonText,
} from "../../../../styledComponents/common";
import {
  MemberSettingsSection,
  MembersTableHeadContainer,
  MembersTableContainer,
  MemberNameDiv,
  Input,
} from "../../ActivitySettings/membersTableStyles";
import { ToggleButton, ToggleButtonRound } from "../timesheetSettingsStyles";
import {
  CommImageNameComp,
  CommFirstRoundLetterCompNew,
} from "../../../Members/CommonComponents";

import ComponentCircleLoader from "../../../../components/Loaders/ComponentCircleLoader";
import InfoTooltip from "../../../../components/Tooltip/InfoTooltip";
import NoDataComponent from "../../../../components/NoDataComponent/NoDataComponent";

import TopPagination from "../../../../components/Pagination/TopPagination/TopPagination";
import BottomPagination from "../../../../components/Pagination/BottomPagination/BottomPagination";

import searchIcon from "../../../../assets/img/icons/search.svg";

const MembersTable = (props) => {
  const [memberList, setMemberList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const {
    selectedOrganization,
    membersList,
    memberSettingsChange,
    // searchTerm,
    // onSearchTermChange,
    history,
    totalMembersCount,
    currentPageNumber,
    onPageChange,
  } = props;

  useEffect(() => {
    setMemberList(membersList);
  }, [membersList]);

  useEffect(() => {
    if (membersList && membersList.length > 0) {
      const originalList = JSON.parse(JSON.stringify(membersList));
      if (searchTerm && searchTerm !== "") {
        let filteredList = [];
        originalList.forEach((e) => {
          let searchField;
          searchField = e.name;

          if (
            searchTerm &&
            searchField
              .toLowerCase()
              .indexOf(searchTerm.toLowerCase().trim()) === -1
          ) {
            return;
          }
          filteredList.push(e);
        });
        setMemberList([...filteredList]);
      } else if (searchTerm === "") {
        setMemberList(membersList);
      }
    }
  }, [searchTerm]);

  const onSearchTermChange = (event) => {
    if (event.target.value != null || event.target.value != undefined) {
      setSearchTerm(event.target.value);
    }
  };

  return (
    <MemberSettingsSection
      margin={
        selectedOrganization &&
        (selectedOrganization.role !== "owner" ||
          selectedOrganization.role !== "admin")
          ? "20px 0 0"
          : "0"
      }
    >
      {memberList && memberList.length > 0 ? (
        <>
          <CommonGrid columns="45% 55%" gap="2em" alignItem="center">
            <CardTitle fontSize="16px" margin="0 0 0 40px">
              Control Individual Member
            </CardTitle>
            <Input
              backgroundImage={searchIcon}
              type="text"
              value={searchTerm}
              placeholder="Search by member name..."
              onChange={(e) => onSearchTermChange(e)}
            />
          </CommonGrid>
          {totalMembersCount && totalMembersCount > 0 ? (
            <TopPagination
              itemName="members"
              totalCount={totalMembersCount}
              currentPage={currentPageNumber}
              onPageChange={(page) => onPageChange(page)}
              pageSize={50}
              padding="0 40px"
            />
          ) : null}
          <MembersTableHeadContainer gap="2em">
            <TableItem>
              <TableText>Member Name</TableText>
            </TableItem>
            <TableItem>
              <InfoTooltip
                mainText="Approval Status"
                toolTipText={`Control whether timesheet approval is required for the user or not.`}
                marginTop="0px"
                top="-60px"
                width="260px"
                label
              />
            </TableItem>
          </MembersTableHeadContainer>
          {memberList.map((member, index) => (
            <MembersTableContainer key={index} gap="2em">
              <TableItem>
                <MemberNameDiv
                  onClick={() =>
                    member.userId &&
                    history.push(`/user/member-details?id=${member.userId}`)
                  }
                >
                  <CommImageNameComp
                    index={index}
                    imgSource={member.avatar}
                    name={member.name}
                    color={member.color}
                    size="36px"
                    hoverUnderline
                  />
                </MemberNameDiv>
              </TableItem>
              <TableItem>
                <CommonGrid
                  columns="36px 30px"
                  gap="0.25em"
                  justifyContent="start"
                >
                  <ToggleButton
                    onClick={() => memberSettingsChange(member)}
                    active={member.settings}
                  >
                    <ToggleButtonRound active={member.settings} />
                  </ToggleButton>
                  <CommonText
                    $label={!member.settings}
                    fontWeight="500"
                    fontSize="13px"
                    margin="0"
                  >
                    {member.settings ? "ON" : "OFF"}
                  </CommonText>
                </CommonGrid>
              </TableItem>
            </MembersTableContainer>
          ))}
          {totalMembersCount && totalMembersCount > 0 ? (
            <BottomPagination
              totalCount={totalMembersCount}
              currentPage={currentPageNumber}
              onPageChange={(page) => onPageChange(page)}
              pageSize={50}
            />
          ) : null}
        </>
      ) : memberList && memberList.length === 0 && searchTerm ? (
        <>
          <CommonGrid columns="45% 55%" gap="2em" alignItem="center">
            <CardTitle fontSize="16px" margin="0 0 0 40px">
              Control Individual Member
            </CardTitle>
            <Input
              backgroundImage={searchIcon}
              type="text"
              value={searchTerm}
              placeholder="Search by member name..."
              onChange={(e) => onSearchTermChange(e)}
            />
          </CommonGrid>
          <NoDataComponent
            title="No member found with this name"
            padding="30px 0"
            imageHeight="130px"
            imageWidth="130px"
            titleFontSize="16px"
          />
        </>
      ) : null}
    </MemberSettingsSection>
  );
};

export default MembersTable;
