import { useState } from "react";
import {
  AssigneesManageImgTextGrid,
  AssigneesManageRoundImage,
} from "../../styledComponents/createProject";
import {
  RoundMiniImage,
  FirstWordRound,
  TableText,
  MailSendSection,
  ResendInvite,
  CommonFlex,
  CommonText,
} from "../../styledComponents/common";
import {
  ActivityTextSection,
  TimesheetApprovalSettingsBadge,
} from "../../styledComponents/members";

import ToggleButton from "../../components/ToggleButton/ToggleButton";
import HelpTooltip from "../../components/Tooltip/HelpTooltip";
import FirstRoundLetterComp from "../../components/FirstRoundLetter/FirstRoundLetter";

export const ApprovalSettingsSection = (props) => {
  const { margin, active, hasTooltip, handleChange, memberId, isAdmin } = props;

  const handleClick = () => {
    if (memberId) {
      handleChange(memberId, !active);
    }
  };
  return (
    <CommonFlex margin={margin || "10px 0 0 0"} gap="12px">
      <CommonText $label fontWeight={!hasTooltip && "500"}>
        Timesheet Approval
      </CommonText>
      {hasTooltip && (
        <HelpTooltip toolTipText="Control whether this member will require timesheet approval or not." />
      )}
      {isAdmin ? (
        <ToggleButton
          active={active}
          handleClick={handleClick}
          margin={hasTooltip && "0 0 0 10px"}
        />
      ) : (
        <TimesheetApprovalSettingsBadge active={active}>
          {active ? "ON" : "OFF"}
        </TimesheetApprovalSettingsBadge>
      )}
    </CommonFlex>
  );
};

export const CommImageNameComp = (props) => (
  <AssigneesManageImgTextGrid>
    {props.imgSource ? (
      <AssigneesManageRoundImage imageSize={props.size}>
        <RoundMiniImage src={props.imgSource} alt="" imageSize={props.size} />
      </AssigneesManageRoundImage>
    ) : (
      <FirstRoundLetterComp
        fontSize={props.fontSize ? props.fontSize : "14px"}
        size={props.size}
        text={props.name}
        backColor={props.index}
        color={props.color}
      />
    )}

    {props.email ? (
      <ActivityTextSection style={{ alignContent: "center" }}>
        <TableText name>{props.name}</TableText>
        <TableText fontWeight="500">{props.email}</TableText>
      </ActivityTextSection>
    ) : (
      <TableText hoverUnderline={props.hoverUnderline ?? false} name>
        {props.name}
      </TableText>
    )}
  </AssigneesManageImgTextGrid>
);

export const CommImageInviteComp = (props) => (
  <AssigneesManageImgTextGrid>
    <CommFirstRoundLetterCompNew
      text={props.member?.email}
      backColor={props.index}
      size={props.size}
      showText={false}
    />
    <MailSendSection>
      <TableText name>{props.member.email}</TableText>
      <ResendInvite onClick={() => props.resendInvite(props.member)}>
        Resend Invite
      </ResendInvite>
    </MailSendSection>
  </AssigneesManageImgTextGrid>
);

export const CommFirstRoundLetterComp = ({
  text,
  backColor,
  size,
  fontSize,
}) => {
  const colorList = [
    { color: "#9A96F9" },
    { color: "#73C0FF" },
    { color: "#F9828B" },
    { color: "#F9D059" },
  ];
  let selectedIndex = 0;
  if (backColor < 4) {
    selectedIndex = backColor;
  } else {
    selectedIndex = backColor % 4;
  }

  return (
    <FirstWordRound
      backColor={colorList[selectedIndex].color}
      size={size}
      fontSize={fontSize}
    >
      {" "}
      {text.slice(0, 1).toUpperCase()}{" "}
    </FirstWordRound>
  );
};
export const CommFirstRoundLetterCompNew = ({
  text,
  backColor,
  size,
  showText = true,
}) => {
  const colorList = [
    { color: "#9A96F9" },
    { color: "#73C0FF" },
    { color: "#F9828B" },
    { color: "#F9D059" },
  ];
  const getInitials = (string) => {
    if (string === "" || string === undefined) return "";
    var names = string.split(" "),
      initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  };
  let selectedIndex = 0;
  if (backColor < 4) {
    selectedIndex = backColor;
  } else {
    selectedIndex = backColor % 4;
  }

  return (
    <AssigneesManageImgTextGrid columnGap={showText ? "15px" : "0px"}>
      <FirstWordRound backColor={colorList[selectedIndex].color} size={size}>
        {" "}
        {getInitials(text)}{" "}
      </FirstWordRound>
      {showText && <TableText name>{text}</TableText>}
    </AssigneesManageImgTextGrid>
  );
};
