import { connect } from "react-redux";

// Component
import VerificationCode from "./VerificationCode";
// Actions
import {
  verifyCode,
  resendVerificationCode,
  resetVerifyCode,
} from "./verificationCodeActions";

// Store props to map with current state
const mapStateToProps = (state) => ({
  isLoading: state.verificationCode.isLoading,
  verificationCode: state.verificationCode,
  advertise: state.signUp.advertise,
});

// Actions prop to dispatch
const mapDispatchToProps = (dispatch) => ({
  verifyCode: (details) => dispatch(verifyCode({ ...details })),
  resendVerificationCode: (details) =>
    dispatch(resendVerificationCode({ ...details })),
  resetVerifyCode: () => dispatch(resetVerifyCode()),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(VerificationCode);
