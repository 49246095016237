import { useState, useEffect } from "react";
import ActivityFilter from "../activityFilterContainer";
import UrlSection from "./UrlSection";

import { PageTitle, CommonGrid } from "../../../styledComponents/common";

import NoDataComponent from "../../../components/NoDataComponent/NoDataComponent";
import ComponentCircleLoader from "../../../components/Loaders/ComponentCircleLoader";
import SettingsDisabled from "../../../components/SettingsDisabled/SettingsDisabled";

const ActivityURLs = (props) => {
  const [timesheetHasUrls, setTimesheetHasUrls] = useState(false);

  const { urls } = props;

  useEffect(() => {
    let hasUrls = false;
    if (urls && urls.length > 0) {
      urls.some((urlSection) => {
        if (urlSection.urls && urlSection.urls.length > 0) {
          hasUrls = true;
          return true;
        }
        return false;
      });
    }
    setTimesheetHasUrls(hasUrls);
  }, [urls]);

  return (
    <div className="content">
      <CommonGrid alignItem="center">
        <PageTitle>URLs</PageTitle>
      </CommonGrid>
      <ActivityFilter title="URLs" />
      {props.isLoading ? (
        <ComponentCircleLoader />
      ) : (
        <>
          {props.selectedOrganization?.configuration?.url_track ? (
            <>
              {props.urls?.length > 0 ? (
                <div style={{ marginTop: `30px` }}>
                  {timesheetHasUrls ? (
                    props.urls.map((urlSection, index) =>
                      urlSection.urls?.length > 0 ? (
                        <UrlSection key={index} urlSection={urlSection} />
                      ) : null
                    )
                  ) : (
                    <NoDataComponent title="Sorry! No URLs data found." />
                  )}
                </div>
              ) : (
                <NoDataComponent title="Sorry! No URLs data found." />
              )}
            </>
          ) : (
            <SettingsDisabled feature={`'URL Tracking'`} />
          )}
        </>
      )}
    </div>
  );
};

export default ActivityURLs;
