import { useState, useEffect, Fragment, useMemo } from "react";

import {
  AssigneeSection,
  Cross,
  CrossIcon,
} from "../../../styledComponents/createProject";

import {
  InputSection,
  AssigneesHeaderContainer,
  AssigneesInfoContainer,
  InputTickSection,
  TickCircle,
  TeamProjectsDivContainer,
} from "../../../styledComponents/teams";
import { RedStar } from "../../../styledComponents/members";

import {
  ToolTipContainer,
  InnerDivTooltip,
  TooltipText,
} from "../../../styledComponents/clients";

import {
  PageTitle,
  Container,
  CardTitle,
  ContentHeader,
  CommonText,
  InputLabel,
  InputField,
  FormAlert,
} from "../../../styledComponents/common";
import { PrimaryButton, WhiteButton } from "../../../styledComponents/buttons";

import Select from "../../../components/ReactSelectDropdown";

import { AddAssigneeDropdown } from "../AddAssigneeDropdown";

import { CommImageNameComp } from "../CommonComponents";
import BackButtonComponent from "../../../components/BackButtonComponent/BackButtonComponent";
import AssignButtonLoader from "../../../components/AssignButtonLoader/AssignButtonLoader";
import DropdownLoader from "../../../components/DropdownLoader/DropdownLoader";
import DropdownStyles from "../../../components/DropdownStyle/DropdownStyle";

import InfoTooltip from "../../../components/Tooltip/InfoTooltip";
import AddProjectDropdown from "../../../components/CustomDropdown/AddProjectDropdown";

import { checkUrl, checkHtml } from "../../../utils/helper";

import TickDark from "../../../assets/img/icons/tick.svg";
import Chat from "../../../assets/img/icons/chat.svg";
import crossIcon from "../../../assets/img/icons/cross_black.svg";
import moment from "moment-timezone";

const AddTeam = (props) => {
  const [teamName, setTeamName] = useState("");
  const [assignees, setAssignees] = useState([]);
  const [visibility, setVisibility] = useState(0);
  const [saveErrors, setSaveErrors] = useState({});
  const [toolTip, setToolTip] = useState(false);

  const roleOptions = [
    {
      value: "manager",
      label: "Team Manager",
    },
    {
      value: "member",
      label: "Team Member",
    },
  ];

  useEffect(() => {
    props.clearTeamMembersList();
    return () => {
      props.clearCreatedTeamId();
      props.clearTeamMembersList();
    };
  }, []);

  useMemo(() => {
    if (props.createdTeamId) {
      if (props.selectedOrganization && props.selectedOrganization.id) {
        const payload = {
          organization_id: props.selectedOrganization.id,
          team_id: props.createdTeamId,
        };
        props.getTeamProjectsList(payload);
      }
    }
  }, [props.createdTeamId]);

  useEffect(() => {
    if (props.teamMembersList && props.teamMembersList.length > 0) {
      let membersList = [];
      props.teamMembersList.map((member) => {
        membersList.push({
          id: member.id,
          userId: member.user.id,
          name: `${member.user.first_name}${
            member.user.last_name ? ` ${member.user.last_name}` : ``
          }`,
          manageTeam: member.can_manage
            ? { value: "manager", label: "Team Manager" }
            : { value: "member", label: "Team Member" },
          avatar: member.user.avatar ?? null,
          color: member.user.color ?? null,
        });
      });
      setAssignees(membersList);
    } else if (props.teamMembersList && props.teamMembersList.length === 0) {
      setAssignees([]);
    }
  }, [props.teamMembersList]);

  const onTeamNameChange = (e) => {
    setSaveErrors({});
    setTeamName(e.target.value);
  };

  const showAssignee = (state) => {
    setVisibility(state);
  };

  const toolTipChange = () => {
    setToolTip(!toolTip);
  };

  const updateAssingeeList = (person) => {
    if (
      props.selectedOrganization &&
      props.selectedOrganization.id &&
      props.createdTeamId
    ) {
      let payload = {
        organization_id: props.selectedOrganization.id,
        team_id: props.createdTeamId,
        user_id: person.id,
      };
      props.addTeamMember(payload);
    }
  };

  const handleDeleteMember = (assigneeId) => {
    if (
      props.selectedOrganization &&
      props.selectedOrganization.id &&
      props.createdTeamId &&
      assigneeId
    ) {
      let payload = {
        organization_id: props.selectedOrganization.id,
        team_id: props.createdTeamId,
        user_id: assigneeId,
      };
      props.removeTeamMember(payload);
    }
  };

  const checkError = () => {
    let saveErrors = {};
    let formIsValid = true;
    if (!teamName.trim()) {
      saveErrors["teamName"] = "Team name is required.";
      formIsValid = false;
    } else if (checkUrl(teamName)) {
      saveErrors["teamName"] = "Team name can not contain url.";
      formIsValid = false;
    } else if (checkHtml(teamName)) {
      saveErrors["teamName"] = "Invalid team name.";
      formIsValid = false;
    }

    setSaveErrors(saveErrors);
    return formIsValid;
  };

  const handleSave = () => {
    if (checkError()) {
      if (props.selectedOrganization && props.selectedOrganization.id) {
        let payload = {
          organization_id: props.selectedOrganization.id,
          name: teamName,
        };
        props.addTeam(payload);
        setToolTip(false);
      }
    }
  };
  const onRoleChange = (e, memberId) => {
    if (
      props.selectedOrganization &&
      props.selectedOrganization.id &&
      props.createdTeamId &&
      memberId
    ) {
      if (e.value === "manager") {
        let payload = {
          organization_id: props.selectedOrganization.id,
          team_id: props.createdTeamId,
          member_id: memberId,
          can_manage: true,
        };
        props.updateTeamMember(payload);
      } else if (e.value === "member") {
        let payload = {
          organization_id: props.selectedOrganization.id,
          team_id: props.createdTeamId,
          member_id: memberId,
          can_manage: false,
        };
        props.updateTeamMember(payload);
      }
    }
  };
  const handleAssignProject = (project) => {
    if (
      props.selectedOrganization &&
      props.selectedOrganization.id &&
      props.createdTeamId &&
      project &&
      project.id
    ) {
      let payload = {
        organization_id: props.selectedOrganization.id,
        project_id: project.id,
        team_id: props.createdTeamId,
        projectData: project,
        // getDetails: true,
        getProjects: true,
      };
      props.assignProject(payload);
    }
  };

  return (
    <div className="content">
      <PageTitle>Teams</PageTitle>
      <ContentHeader>
        <BackButtonComponent
          onClick={() => props.history.push("/user/teams")}
          subTitle="Back to all teams"
        />
      </ContentHeader>
      <Container padding="30px">
        <CardTitle>Team Details</CardTitle>
        <InputSection>
          <InputLabel>
            Team name <RedStar>*</RedStar>
          </InputLabel>
          <InputTickSection
            columns={props.isLoading && "auto 60px"}
            margin="6px 0 0 0"
          >
            <InputField
              style={{
                minWidth: "340px",
              }}
              type="text"
              placeholder="Write team name"
              value={teamName}
              onChange={(e) => onTeamNameChange(e)}
              error={
                saveErrors &&
                saveErrors["teamName"] &&
                saveErrors["teamName"] !== undefined
              }
            />
            {!props.createdTeamId && (
              <>
                {props.isLoading ? (
                  <DropdownLoader loading />
                ) : (
                  <TickCircle
                    onClick={() => handleSave()}
                    onMouseEnter={() => toolTipChange()}
                    onMouseLeave={() => toolTipChange()}
                  >
                    <img src={TickDark} alt="tick" width="22px" />
                    {toolTip && (
                      <ToolTipContainer style={{ left: `10px` }}>
                        <InnerDivTooltip>
                          <img src={Chat} alt="chat" width="64px" />
                          <TooltipText>Save</TooltipText>
                        </InnerDivTooltip>
                      </ToolTipContainer>
                    )}
                  </TickCircle>
                )}
              </>
            )}
          </InputTickSection>
          {saveErrors &&
            saveErrors["teamName"] &&
            saveErrors["teamName"] !== undefined && (
              <FormAlert>{saveErrors["teamName"]}</FormAlert>
            )}
        </InputSection>
        {props.createdTeamId && (
          <>
            <AssigneeSection>
              <CommonText $label>Add Team Members</CommonText>
              <AddAssigneeDropdown
                isLoading={props.memberListIsLoading}
                assignees={assignees}
                visibilityChange={showAssignee}
                updateState={updateAssingeeList}
                membersList={props.orgMembersList}
                getMembersList={props.getOrganizationMembersList}
                selectedOrganization={props.selectedOrganization}
                assigneeRemove={handleDeleteMember}
                teamMemberIsLoading={props.teamMemberIsLoading}
                loadingMemberId={props.loadingMemberId}
              />
            </AssigneeSection>
            {assignees && assignees.length !== 0 && (
              <AssigneesHeaderContainer>
                <CommonText $label>Team Members</CommonText>
                <InfoTooltip
                  label
                  mainText="Team Role"
                  toolTipText="Select whether the member is a 'Team Manager' or a 'Team Member'"
                />
              </AssigneesHeaderContainer>
            )}
            <AssigneesInfoContainer style={{ padding: "15px 0px 0px" }}>
              {assignees &&
                assignees.length > 0 &&
                assignees.map((assignee, index) => {
                  return props.teamMemberIsLoading &&
                    props.loadingMemberId &&
                    props.loadingMemberId === assignee.id ? (
                    <Fragment key={index}>
                      <DropdownLoader loading />
                      <div />
                      <div />
                    </Fragment>
                  ) : (
                    <Fragment key={index}>
                      <CommImageNameComp
                        personImg={
                          assignee.avatar ? (
                            <img
                              src={assignee.avatar}
                              alt="addPeople"
                              style={{
                                width: "40px",
                                height: "40px",
                                borderRadius: "50%",
                                border: `1px solid #fff`,
                              }}
                            />
                          ) : null
                        }
                        isManager={
                          assignee.manageTeam.value === "manager" ? true : false
                        }
                        personName={assignee.name}
                        personColor={assignee.color}
                        index={index}
                      />
                      <Select
                        isSearchable={false}
                        options={roleOptions}
                        value={assignee.manageTeam}
                        onChange={(e) => onRoleChange(e, assignee.id)}
                        styles={DropdownStyles()}
                      />
                      <Cross onClick={() => handleDeleteMember(assignee.id)}>
                        <CrossIcon src={crossIcon} alt="" />
                      </Cross>
                    </Fragment>
                  );
                })}
            </AssigneesInfoContainer>
            {assignees && assignees.length > 0 && (
              <div style={{ padding: "30px 0px" }}>
                <CommonText $label margin="0 0 15px 0">
                  Assigned Projects
                </CommonText>
                <TeamProjectsDivContainer>
                  {props.teamProjectsList &&
                    props.teamProjectsList.length > 0 &&
                    props.teamProjectsList.map((project, index) => (
                      <WhiteButton
                        key={index}
                        type="cancel"
                        bgColor="#fcfdfe"
                        onClick={() =>
                          props.history.push(
                            `/user/project-details?id=${project.id}&type=${
                              project.is_active ? "active" : "archived"
                            }`
                          )
                        }
                      >
                        {project.name}
                      </WhiteButton>
                    ))}
                  {props.projectIsLoading ? (
                    <AssignButtonLoader />
                  ) : (
                    <AddProjectDropdown
                      isLoading={props.projectListIsLoading}
                      selectedOrganization={props.selectedOrganization}
                      assignedProjects={props.teamProjectsList}
                      getProjectList={props.getProjectShortList}
                      updateState={handleAssignProject}
                      projectsList={props.projectList}
                    />
                  )}
                </TeamProjectsDivContainer>
              </div>
            )}
            <div style={{ margin: "10px  0 0 0" }}>
              <PrimaryButton
                onClick={() => props.history.push("/user/teams")}
                padding="0 30px"
              >
                Save & Close
              </PrimaryButton>
            </div>
          </>
        )}
      </Container>
    </div>
  );
};

export default AddTeam;
