import { TableItem } from "styledComponents/teams";
import {
  CustomReportCheckboxContainer,
  CustomReportListTableContainer,
} from "./customReportStyles";
import {
  CommonFlex,
  CommonText,
  Container,
  TableText,
} from "styledComponents/common";
import { ColoredButton } from "styledComponents/buttons";
import moment from "moment";
import check from "../../../assets/img/icons/check.svg";
import CustomReportDeleteModal from "./CustomReportDeleteModal";
import { useEffect, useState } from "react";

const CustomReportListTable = ({
  data,
  selectedReports,
  setSelectedReports,
  deleteCustomReports,
  organization,
  history,
  isLoading,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const selectAllReports = () => {
    const allReportIds = data.map((report) => report.id);
    setSelectedReports(allReportIds);
  };
  const unselectAllReports = () => {
    setSelectedReports([]);
  };
  const handleSelectReport = (id) => {
    setSelectedReports([...selectedReports, id]);
  };
  const handleUnselectReport = (id) => {
    setSelectedReports(selectedReports?.filter((reportID) => reportID !== id));
  };
  const handleDeleteReports = () => {
    if (selectedReports?.length > 0 && organization?.id) {
      deleteCustomReports({
        organization_id: organization.id,
        report_ids: selectedReports,
      });
    }
  };

  useEffect(() => {
    unselectAllReports();
    setIsModalOpen(false);
  }, [data]);
  const toggle = () => {
    setIsModalOpen(!isModalOpen);
  };
  return (
    <>
      <CustomReportDeleteModal
        isOpen={isModalOpen}
        handleDelete={handleDeleteReports}
        toggle={toggle}
        isLoading={isLoading}
      />
      <CustomReportListTableContainer
        style={{ borderBottom: `1px solid #C2CCE1` }}
      >
        <TableItem>
          <CommonFlex>
            <CustomReportCheckboxContainer
              onClick={() => {
                data?.length === selectedReports?.length
                  ? unselectAllReports()
                  : selectAllReports();
              }}
            >
              {data?.length === selectedReports?.length && (
                <img src={check} alt="" height={"12px"} width={"12px"} />
              )}
            </CustomReportCheckboxContainer>
            <TableText>Report Name</TableText>
          </CommonFlex>
        </TableItem>
        <TableItem>
          <TableText>Created Date</TableText>
        </TableItem>
        {/* <TableItem>
          <TableText>Shared with</TableText>
        </TableItem> */}
        <TableItem>
          <TableText>Date Range</TableText>
        </TableItem>

        <TableItem>
          <ColoredButton
            disabled={!(selectedReports?.length > 0)}
            type="delete"
            padding={false ? "0 15px" : "0 30px"}
            onClick={toggle}
          >
            Delete
          </ColoredButton>
        </TableItem>
      </CustomReportListTableContainer>{" "}
      {data?.map((d) => {
        return (
          <CustomReportListTableContainer>
            <TableItem>
              <CommonFlex>
                <CustomReportCheckboxContainer
                  onClick={() => {
                    selectedReports?.includes(d.id)
                      ? handleUnselectReport(d.id)
                      : handleSelectReport(d.id);
                  }}
                >
                  {selectedReports?.includes(d.id) && (
                    <img src={check} alt="" height={"12px"} width={"12px"} />
                  )}
                </CustomReportCheckboxContainer>{" "}
                <TableText
                  onClick={() =>
                    history.push(
                      "/user/reports/custom-report-details?id=" + d.id
                    )
                  }
                  name
                  hoverPointer
                  hoverUnderline
                >
                  {d.name}
                </TableText>
              </CommonFlex>
            </TableItem>
            <TableItem>
              <CommonText>
                {organization?.timezone
                  ? moment
                      .tz(d.created_at, "America/Chicago")
                      .tz(organization.timezone)
                      .format("D MMM, YYYY")
                  : moment(d.created_at).format("D MMM, YYYY")}
              </CommonText>
            </TableItem>
            {/* <TableItem>
              <CommonText>{d.sharedWith}</CommonText>
            </TableItem> */}
            <TableItem>
              <CommonText>
                {moment(d.start_date).format("D MMMM, YYYY")}
                {" - "}
                {moment(d.end_date).format("D MMMM, YYYY")}
              </CommonText>
            </TableItem>
          </CustomReportListTableContainer>
        );
      })}
    </>
  );
};

export default CustomReportListTable;
